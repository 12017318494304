export const columns = () => [
  {
    key: "sku",
    dataIndex: "sku",
    title: "sku",
  },
  {
    key: "variant_code",
    dataIndex: "variant_code",
    title: "variant_code",
  },
  {
    key: "activated",
    dataIndex: "activated",
    title: "Activated",
  },
  {
    key: "all_season",
    dataIndex: "all_season",
    title: "all_season",
  },
  {
    key: "availability",
    dataIndex: "availability",
    title: "availability",
  },
  {
    key: "created_at",
    dataIndex: "created_at",
    title: "created_at",
  },
  {
    key: "dispo",
    dataIndex: "dispo",
    title: "dispo",
  },
  {
    key: "ekgrp",
    dataIndex: "ekgrp",
    title: "ekgrp",
  },
  {
    key: "mage_status",
    dataIndex: "mage_status",
    title: "mage_status",
  },
  {
    key: "pfsitem_status",
    dataIndex: "pfsitem_status",
    title: "pfsitem_status",
  },
  {
    key: "pricat_no",
    dataIndex: "pricat_no",
    title: "pricat_no",
  },
  {
    key: "purchasing_org",
    dataIndex: "purchasing_org",
    title: "purchasing_org",
  },
  {
    key: "season_code",
    dataIndex: "season_code",
    title: "season_code",
  },
  {
    key: "updated_at",
    dataIndex: "updated_at",
    title: "updated_at",
  },
];
