import { Select } from "antd";

const searchSelectProps = {
  showSearch: true,
  filterOption: (input, option) =>
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
};

export const columns = (changedData, setChangedData, lookups) => [
  {
    key: "sku",
    dataIndex: "sku",
    title: "sku",
  },
  {
    key: "variant_code",
    dataIndex: "variant_code",
    title: "variant_code",
  },
  {
    key: "gtin13",
    dataIndex: "gtin13",
    title: "GTIN-13",
  },
  {
    key: "stylename",
    dataIndex: "stylename",
    title: "stylename",
  },
  {
    key: "activated",
    dataIndex: "activated",
    title: "activated",
  },
  {
    key: "age_group",
    dataIndex: "age_group",
    title: "age_group",
  },
  {
    key: "availability",
    dataIndex: "availability",
    title: "availability",
  },
  {
    key: "breit",
    dataIndex: "breit",
    title: "breit",
  },
  {
    key: "cc8",
    dataIndex: "cc8",
    title: "cc8",
  },
  {
    key: "cc10",
    dataIndex: "cc10",
    title: "cc10",
  },
  {
    key: "country_of_origin",
    dataIndex: "country_of_origin",
    title: "country_of_origin",
  },
  {
    key: "created_at",
    dataIndex: "created_at",
    title: "created_at",
  },
  {
    key: "ekwsl",
    dataIndex: "ekwsl",
    title: "ekwsl",
  },
  {
    key: "fedas",
    dataIndex: "fedas",
    title: "fedas",
    width: 200,
    render: (text, record) => (
      <Select
        {...searchSelectProps}
        style={{ width: "100%" }}
        defaultValue={text}
        options={lookups?.fedas}
        onChange={(val) => setChangedData([...changedData, { changed: record.sku, fedas: val }])}
      />
    ),
  },
  {
    key: "gender",
    dataIndex: "gender",
    title: "gender",
  },
  {
    key: "gewei",
    dataIndex: "gewei",
    title: "gewei",
  },
  {
    key: "gross_weight",
    dataIndex: "gross_weight",
    title: "gross_weight",
  },
  {
    key: "hoehe",
    dataIndex: "hoehe",
    title: "hoehe",
  },
  {
    key: "laeng",
    dataIndex: "laeng",
    title: "laeng",
  },
  {
    key: "mage_attribute_set_id",
    dataIndex: "mage_attribute_set_id",
    title: "mage_attribute_set_id",
  },
  {
    key: "mage_color",
    dataIndex: "mage_color",
    title: "mage_color",
  },
  {
    key: "mage_colormfr",
    dataIndex: "mage_colormfr",
    title: "mage_colormfr",
  },
  {
    key: "mage_colornum",
    dataIndex: "mage_colornum",
    title: "mage_colornum",
  },
  {
    key: "mage_eid",
    dataIndex: "mage_eid",
    title: "mage_eid",
  },
  {
    key: "mage_size",
    dataIndex: "mage_size",
    title: "mage_size",
  },
  {
    key: "mage_sizemfr",
    dataIndex: "mage_sizemfr",
    title: "mage_sizemfr",
  },
  {
    key: "mage_status",
    dataIndex: "mage_status",
    title: "mage_status",
  },
  {
    key: "matkl",
    dataIndex: "matkl",
    title: "matkl",
  },
  {
    key: "mbrsh",
    dataIndex: "mbrsh",
    title: "mbrsh",
  },
  {
    key: "meabm",
    dataIndex: "meabm",
    title: "meabm",
  },
  {
    key: "meins",
    dataIndex: "meins",
    title: "meins",
  },
  {
    key: "mfrnr",
    dataIndex: "mfrnr",
    title: "mfrnr",
  },
  {
    key: "mfrpn",
    dataIndex: "mfrpn",
    title: "mfrpn",
  },
  {
    key: "modelcolor_id",
    dataIndex: "modelcolor_id",
    title: "modelcolor_id",
  },
  {
    key: "mtart",
    dataIndex: "mtart",
    title: "mtart",
  },
  {
    key: "name_erp",
    dataIndex: "name_erp",
    title: "name_erp",
  },
  {
    key: "name_mage",
    dataIndex: "name_mage",
    title: "name_mage",
  },
  {
    key: "net_weight",
    dataIndex: "net_weight",
    title: "net_weight",
  },
  {
    key: "norspo",
    dataIndex: "norspo",
    title: "norspo",
  },
  {
    key: "pfshorizontal_component",
    dataIndex: "pfshorizontal_component",
    title: "pfshorizontal_component",
  },
  {
    key: "pfshorz_component_group",
    dataIndex: "pfshorz_component_group",
    title: "pfshorz_component_group",
  },
  {
    key: "pfsitem_status",
    dataIndex: "pfsitem_status",
    title: "pfsitem_status",
  },
  {
    key: "pfsvert_component_group",
    dataIndex: "pfsvert_component_group",
    title: "pfsvert_component_group",
  },
  {
    key: "pfsvertical_component",
    dataIndex: "pfsvertical_component",
    title: "pfsvertical_component",
  },
  {
    key: "product_id",
    dataIndex: "product_id",
    title: "product_id",
  },
  {
    key: "pstat",
    dataIndex: "pstat",
    title: "pstat",
  },

  {
    key: "tariff_no",
    dataIndex: "tariff_no",
    title: "tariff_no",
  },
  {
    key: "tax_class",
    dataIndex: "tax_class",
    title: "tax_class",
  },
  {
    key: "tragr",
    dataIndex: "tragr",
    title: "tragr",
  },
  {
    key: "updated_at",
    dataIndex: "updated_at",
    title: "updated_at",
  },
  {
    key: "voleh",
    dataIndex: "voleh",
    title: "voleh",
  },
  {
    key: "volum",
    dataIndex: "volum",
    title: "volum",
  },
  {
    key: "vpsta",
    dataIndex: "vpsta",
    title: "vpsta",
  },
  {
    key: "yoda_base_color_code",
    dataIndex: "yoda_base_color_code",
    title: "yoda_base_color_code",
  },
  {
    key: "yoda_base_size_code",
    dataIndex: "yoda_base_size_code",
    title: "yoda_base_size_code",
  },
  {
    key: "yoda_description1",
    dataIndex: "yoda_description1",
    title: "yoda_description1",
  },
  {
    key: "yoda_description2",
    dataIndex: "yoda_description2",
    title: "yoda_description2",
  },
];
