import { useRef, useState, useEffect, useMemo } from "react";
import { Empty } from "antd";
import SelectionBaseTable from "components/Table/SelectionBaseTable/SelectionBaseTable.component";
import { View, SortOrder } from "utils/Enums.util";
import { skuColumns, styleColumns } from "../PoColumns";
import ColumnsModal from "components/Modal/ColumnsModal.component";
import { getLocalStorageItem } from "utils/LocalStorage.util";
import { sortByOrderInArray } from "utils/Sort.util";

const PoTables = ({
  view,
  documentType,
  hiddenColumnsList,
  modifiedDataList,
  selectionFilter,
  dataSku,
  dataSkuSorted,
  dataStyleSorted,
  selectedRowKeys,
  handleSetSelectedRowKeysSingle,
  sortState,
  setSortState,
  navigationListItem,
  setNavigationListItem,
  selectedRowParentKeys,
  expandedRowKeys,
  setExpandedRowKeys,
  handleUpdateMrp,
  handleUpdatePrice,
  handleSetDeliveryDate,
  handleUpdateDiscount,
  showColumnModal,
  onCancelColumnSelection,
  selectedGRProcTime,
}) => {
  const [tableSize, setTableSize] = useState({
    width: document.body.clientWidth - 80,
    height: document.body.clientHeight - 40,
  });

  useEffect(() => {
    window.addEventListener("resize", onresize);
    return () => {
      window.removeEventListener("resize", onresize);
    };
  });

  const tableRef = useRef(null);
  const columnsOrder = getLocalStorageItem("columns_order");

  const fixedColumns = useMemo(() => {
    const columnList =
      view === View.SKU
        ? skuColumns(documentType, selectedGRProcTime, hiddenColumnsList)
        : styleColumns(documentType, selectedGRProcTime, hiddenColumnsList);
    return sortByOrderInArray(
      columnList.map((column) => ({
        ...column,
        modifiedDataList,
        handleUpdateMrp,
        handleUpdatePrice,
        handleSetDeliveryDate,
        handleUpdateDiscount,
      })),
      JSON.parse(getLocalStorageItem("columns_order")).po,
      "title"
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    view,
    modifiedDataList,
    documentType,
    hiddenColumnsList,
    handleUpdateMrp,
    handleUpdatePrice,
    handleSetDeliveryDate,
    handleUpdateDiscount,
    selectedGRProcTime,
    columnsOrder,
  ]);

  const shouldShowFooter = useMemo(() => {
    const itemsInTable = selectionFilter === "selected" ? dataSku.filter((d) => selectedRowKeys.includes(d.sku)) : dataSku;
    return itemsInTable.length === 0;
  }, [selectionFilter, selectedRowKeys, dataSku]);

  const styleListBasedOnSelectionFilter = useMemo(() => {
    return dataStyleSorted.filter(
      (style) =>
        selectedRowParentKeys.includes(style.key) ||
        style.children.some(
          (child) =>
            (child.sku && selectedRowKeys.includes(child.sku)) ||
            selectedRowParentKeys.includes(child.key) ||
            child.children?.some((secondchild) => selectedRowKeys.includes(secondchild.sku))
        )
    );
  }, [selectedRowKeys, selectedRowParentKeys, dataStyleSorted]);

  // make table width dynamic
  const onresize = () => setTableSize({ width: document.body.clientWidth - 80, height: document.body.clientHeight - 40 });

  const sortTable = ({ key, order }) => {
    const shouldCancelColumnSort = sortState.key === key && sortState.order === SortOrder.DESC;

    if (shouldCancelColumnSort) {
      setSortState({});
    } else {
      setSortState({ key, order });
    }
  };

  const onExpandedRowsChange = (expandedRows) => {
    const filteredExpandedRows = expandedRows.filter((key) => {
      if (key.includes("@@@")) {
        const stylename = key.substring(0, key.indexOf("@@@"));
        if (expandedRows.includes(stylename)) {
          return true;
        }
        return false;
      }
      return true;
    });
    setExpandedRowKeys(filteredExpandedRows);
  };

  const expandAllChildren = (rowData) => {
    if (!rowData.sku) {
      const expandedKeySet = new Set(expandedRowKeys);

      if (rowData.parentId) {
        expandedKeySet.add(rowData.key);
      } else {
        expandedKeySet.add(rowData.key);
        rowData.children.forEach((child) => expandedKeySet.add(child.key));
      }
      setExpandedRowKeys(Array.from(expandedKeySet));
    }
  };

  return (
    <div id="NavigationTable" tabIndex="-1">
      {view === View.SKU ? (
        <SelectionBaseTable
          rowKey="sku"
          ref={tableRef}
          columns={fixedColumns}
          data={selectionFilter === "selected" ? dataSkuSorted.filter((d) => selectedRowKeys.includes(d.sku)) : dataSkuSorted}
          width={tableSize.width}
          maxHeight={tableSize.height}
          onSelectionChange={handleSetSelectedRowKeysSingle}
          sortBy={sortState}
          fixed
          showSelectionColumn
          rowHeight={40}
          headerHeight={30}
          rowEventHandlers={{ onClick: ({ rowIndex }) => setNavigationListItem({ index: rowIndex }) }}
          rowClassName={({ rowIndex }) => (rowIndex === navigationListItem.index ? "BaseTable__row--higlight" : "")}
          navigationListIndex={navigationListItem.index}
          selectedRowKeys={selectedRowKeys.concat(selectedRowParentKeys)}
          onColumnSort={sortTable}
          footerHeight={shouldShowFooter ? 100 : 0}
          footerRenderer={
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          }
        />
      ) : (
        <SelectionBaseTable
          ref={tableRef}
          columns={fixedColumns}
          data={selectionFilter === "selected" ? styleListBasedOnSelectionFilter : dataStyleSorted}
          width={tableSize.width}
          maxHeight={tableSize.height}
          onSelectionChange={handleSetSelectedRowKeysSingle}
          sortBy={sortState}
          showSelectionColumn
          expandColumnKey="key"
          onColumnSort={sortTable}
          onExpandedRowsChange={onExpandedRowsChange}
          expandedRowKeys={expandedRowKeys}
          fixed
          rowHeight={40}
          headerHeight={30}
          rowEventHandlers={{
            onClick: ({ rowIndex, rowData }) => setNavigationListItem({ index: rowIndex, record: rowData }),
            onDoubleClick: ({ rowData }) => expandAllChildren(rowData),
          }}
          rowClassName={({ rowData }) => (rowData.key === navigationListItem.record?.key ? "BaseTable__row--higlight" : "")}
          navigationListIndex={navigationListItem.index}
          selectedRowKeys={selectedRowKeys.concat(selectedRowParentKeys)}
          footerHeight={shouldShowFooter ? 100 : 0}
          footerRenderer={
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          }
        />
      )}
      {showColumnModal && (
        <ColumnsModal
          visible={showColumnModal}
          onCancel={onCancelColumnSelection}
          localStorageId="po"
          columns={[...new Set(fixedColumns.map((el) => el.title))].filter((el) => el)}
          disabledSkuList={["SKU", "MRP", "Delivery date", "Best price", "Discount"]}
        />
      )}
    </div>
  );
};

export default PoTables;
