// css
import "./PreparePo.page.scss";
import "react-base-table/styles.css";

// 3rd party lib
import React, { useState, useEffect, useMemo } from "react";
import { Button, BackTop, Space } from "antd";
import PropTypes from "prop-types";

// components and functions
import { calculatePutawayDate } from "utils/Date.util";
import { getLocalStorageItem } from "utils/LocalStorage.util";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { sortStrings } from "utils/Sort.util";
import SuccessModal from "./components/SuccessModal.component";
import { keyPressHandler } from "./functions/Keypresshandler";
import PoCards from "./components/PoCards.component";
import { SortOrder, View } from "utils/Enums.util";
import PoTables from "./components/PoTables.component";
import PoToolbar from "./components/PoToolbar.components";
import moment from "moment";

const PreparePo = ({
  dataSku,
  dataStyle,
  vendor,
  poGroup,
  plant,
  handleChangeVendor,
  view,
  handleSetView,
  refreshNotifications,
  handleGlobalFilterChanged,
  allStylenames,
  styleFormatFlatStructure,
  removeAllData,
  availableVendors,
  purchasingGroupList,
}) => {
  const [sortState, setSortState] = useState({});
  const [globalFilter, setGlobalFilter] = useState();
  const [navigationListItem, setNavigationListItem] = useState({ index: -1 });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [orderNumber, setOrderNumber] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [sapCredentials, setSapCredentials] = useState({});
  const [modifiedDataList, setModifiedDataList] = useState([]);
  const [selectionFilter, setSelectionFilter] = useState("all");
  const [documentType, setDocumentType] = useState("NB");
  const [showColumnModal, setShowColumnModal] = useState(false);
  const [hiddenColumnsList, setHiddenColumnsList] = useState(JSON.parse(getLocalStorageItem("hidden_columns")).po);
  const [orderRef, setOrderRef] = useState();
  const [selectedPoGroup, setSelectedPoGroup] = useState(poGroup);
  const [selectedGRProcTime, setSelectedGRProcTime] = useState(3);

  useEffect(() => {
    document.getElementById("NavigationTable")?.addEventListener("keydown", handleKeyPress);
    return () => {
      document.getElementById("NavigationTable")?.removeEventListener("keydown", handleKeyPress);
    };
  });

  useEffect(() => {
    setNavigationListItem({ index: -1 });
    setSortState({});
  }, [view]);

  const handleChangeDocumentType = (value) => {
    setDocumentType(value);

    if (value === "ZPRE") {
      setSelectedGRProcTime(5);
    } else {
      setSelectedGRProcTime(3);
    }
  };

  const selectedRowParentKeys = useMemo(() => {
    if (!selectedRowKeys.length) {
      return [];
    } else {
      const parentKeySet = new Set();
      selectedRowKeys.forEach((sku) => {
        const skuItem = dataSku.find((s) => s.sku === sku);
        const itemsWithSameStyle = dataSku.filter((s) => s.stylename === skuItem.stylename);
        !itemsWithSameStyle.some((i) => !selectedRowKeys.includes(i.sku)) && parentKeySet.add(skuItem.stylename);
        const colorsItems = itemsWithSameStyle.filter((s) => s.color_description === skuItem.color_description);
        !colorsItems.some((i) => !selectedRowKeys.includes(i.sku)) &&
          parentKeySet.add(
            `${colorsItems[0].stylename}@@@${colorsItems[0].color_description ? colorsItems[0].color_description : ""}`
          );
      });
      return Array.from(parentKeySet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys]);

  const dataSkuSorted = useMemo(() => {
    if (!sortState.key || !dataSku.length || view === View.STYLE) {
      return dataSku;
    } else {
      switch (sortState.key) {
        case "mrp":
          return [...dataSku].sort((a, b) => {
            const firstItem = modifiedDataList.find((o) => o.key === a.sku)?.mrp || 0;
            const secondItem = modifiedDataList.find((o) => o.key === b.sku)?.mrp || 0;
            return sortState.order === SortOrder.ASC ? firstItem - secondItem : secondItem - firstItem;
          });
        case "deliveryDate":
          return [...dataSku].sort((a, b) => {
            const firstItem = modifiedDataList.find((o) => o.key === a.sku)?.deliveryDate || "";
            const secondItem = modifiedDataList.find((o) => o.key === b.sku)?.deliveryDate || "";
            return sortState.order === SortOrder.ASC
              ? sortStrings(firstItem, secondItem)
              : sortStrings(secondItem, firstItem);
          });
        case "price":
        case "preorder_price":
          return [...dataSku].sort((a, b) => {
            const firstItemModified = modifiedDataList.find((o) => o.key === a.sku)?.price;
            const firstItemDefaultPrice = documentType === "ZPRE" ? a.preorder_price : a.price;

            const firstItem = firstItemModified ? firstItemModified : firstItemDefaultPrice;

            const secondItemModified = modifiedDataList.find((o) => o.key === b.sku)?.price;
            const secondItemDefaultPrice = documentType === "ZPRE" ? b.preorder_price : b.price;
            const secondItem = secondItemModified ? secondItemModified : secondItemDefaultPrice;
            return sortState.order === SortOrder.ASC ? firstItem - secondItem : secondItem - firstItem;
          });
        case "supplementary_discount_pct":
        case "preorder_discount_pct":
          return [...dataSku].sort((a, b) => {
            const firstItemModified = modifiedDataList.find((o) => o.key === a.sku)?.discount;
            const firstItemDefaultPrice = documentType === "ZPRE" ? a.preorder_discount_pct : a.supplementary_discount_pct;
            const firstItemFallback = firstItemDefaultPrice || 0;

            const firstItem = firstItemModified ? firstItemModified : firstItemFallback;

            const secondItemModified = modifiedDataList.find((o) => o.key === b.sku)?.discount;
            const secondItemDefaultPrice = documentType === "ZPRE" ? b.preorder_discount_pct : b.supplementary_discount_pct;
            const secondItemFallback = secondItemDefaultPrice || 0;
            const secondItem = secondItemModified ? secondItemModified : secondItemFallback;
            return sortState.order === SortOrder.ASC ? firstItem - secondItem : secondItem - firstItem;
          });
        case "selection":
          return [...dataSku].sort((a, b) => {
            const firstItem = selectedRowKeys.includes(a.sku) ? 1 : 0;
            const secondItem = selectedRowKeys.includes(b.sku) ? 1 : 0;
            return sortState.order === SortOrder.ASC ? firstItem - secondItem : secondItem - firstItem;
          });
        case "sales":
          return [...dataSku].sort((a, b) =>
            sortState.order === SortOrder.ASC
              ? a.ZMENG1 - b.ZMENG1 || a.ZMENG2 - b.ZMENG2 || a.ZMENG3 - b.ZMENG3 || a.ZMENG4 - b.ZMENG4
              : b.ZMENG1 - a.ZMENG1 || b.ZMENG2 - a.ZMENG2 || b.ZMENG3 - a.ZMENG3 || b.ZMENG4 - a.ZMENG4
          );
        case "putaway_date":
          return [...dataSku].sort((a, b) => {
            const firstItemDate = modifiedDataList.find((o) => o.key === a.sku)?.deliveryDate;
            const secondItemDate = modifiedDataList.find((o) => o.key === b.sku)?.deliveryDate;
            const firstItem = firstItemDate
              ? calculatePutawayDate(firstItemDate, a.lead_days + selectedGRProcTime, "YYYY-MM-DD")
              : "";
            const secondItem = secondItemDate
              ? calculatePutawayDate(secondItemDate, b.lead_days + selectedGRProcTime, "YYYY-MM-DD")
              : "";
            return sortState.order === SortOrder.ASC
              ? sortStrings(firstItem, secondItem)
              : sortStrings(secondItem, firstItem);
          });
        default:
          return [...dataSku].sort((a, b) => {
            const firstItem = a[sortState.key] || "";
            const secondItem = b[sortState.key] || "";
            return sortState.order === SortOrder.ASC
              ? typeof firstItem === "number" || typeof secondItem === "number"
                ? firstItem - secondItem
                : sortStrings(firstItem, secondItem)
              : typeof firstItem === "number" || typeof secondItem === "number"
              ? secondItem - firstItem
              : sortStrings(secondItem, firstItem);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSku, sortState, SortOrder.ASC, view, View.STYLE]);

  const dataStyleSorted = useMemo(() => {
    if (!sortState.key || !dataStyle.length || view === View.SKU) {
      return dataStyle;
    } else {
      switch (sortState.key) {
        case "mrp":
          return [...dataStyle].sort((a, b) => {
            const firstItem = modifiedDataList.find((o) => o.key === a.key)?.mrp || 0;
            const secondItem = modifiedDataList.find((o) => o.key === b.key)?.mrp || 0;
            return sortState.order === SortOrder.ASC ? firstItem - secondItem : secondItem - firstItem;
          });
        case "deliveryDate":
          return [...dataStyle];
        case "key":
          return [...dataStyle].sort((a, b) => {
            return sortState.order === SortOrder.ASC
              ? sortStrings(a.stylename, b.stylename)
              : sortStrings(b.stylename, a.stylename);
          });
        case "selection":
          return [...dataStyle].sort((a, b) => {
            const firstItem = selectedRowParentKeys.includes(a.key) ? 1 : 0;
            const secondItem = selectedRowParentKeys.includes(b.key) ? 1 : 0;
            return sortState.order === SortOrder.ASC ? firstItem - secondItem : secondItem - firstItem;
          });
        case "sales":
          return [...dataStyle].sort((a, b) =>
            sortState.order === SortOrder.ASC
              ? a.ZMENG1 - b.ZMENG1 || a.ZMENG2 - b.ZMENG2 || a.ZMENG3 - b.ZMENG3 || a.ZMENG4 - b.ZMENG4
              : b.ZMENG1 - a.ZMENG1 || b.ZMENG2 - a.ZMENG2 || b.ZMENG3 - a.ZMENG3 || b.ZMENG4 - a.ZMENG4
          );
        default:
          return [...dataStyle].sort((a, b) => {
            const firstItem = a[sortState.key] || "";
            const secondItem = b[sortState.key] || "";
            return sortState.order === SortOrder.ASC
              ? typeof firstItem === "number" || typeof secondItem === "number"
                ? firstItem - secondItem
                : sortStrings(firstItem, secondItem)
              : typeof firstItem === "number" || typeof secondItem === "number"
              ? secondItem - firstItem
              : sortStrings(secondItem, firstItem);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStyle, sortState, SortOrder.ASC, view, View.SKU]);

  useEffect(() => {
    setNavigationListItem({ index: -1 });
  }, [view]);

  const handleKeyPress = (event) => {
    keyPressHandler(
      event,
      view,
      setNavigationListItem,
      navigationListItem,
      styleFormatFlatStructure,
      allStylenames,
      expandedRowKeys,
      dataSku,
      dataSkuSorted,
      selectedRowKeys,
      selectedRowParentKeys,
      setExpandedRowKeys,
      handleSetSelectedRowKeysSingle
    );
  };

  const onCancelColumnSelection = () => {
    setShowColumnModal(false);
    setHiddenColumnsList(JSON.parse(getLocalStorageItem("hidden_columns")).po);
  };

  const handleGlobalFilter = (input) => {
    const filterValue = input?.target?.value;
    setGlobalFilter(filterValue);
    handleGlobalFilterChanged(filterValue);
  };

  const handleSetSelectedRowKeysSingle = (record, value) => {
    if (value) {
      const newArray = [...selectedRowKeys];
      const skuSet = new Set(newArray);

      if (record.sku) {
        newArray.push(record.sku);
        setSelectedRowKeys(newArray);
      } else {
        record.children.forEach((firstChild) => {
          if (firstChild.children) {
            firstChild.children.forEach((secondChild) => {
              skuSet.add(secondChild.sku);
            });
          } else {
            skuSet.add(firstChild.sku);
          }
        });
        setSelectedRowKeys(Array.from(skuSet));
      }
      if (!vendor) {
        const vendorOfFirstItem = record.children
          ? record.children[0].children
            ? record.children[0].children[0].vendor_no
            : record.children[0].vendor_no
          : record.vendor_no;
        handleChangeVendor(vendorOfFirstItem);
      }
    } else {
      const newArray = [...selectedRowKeys];

      if (record.sku) {
        newArray.splice(newArray.indexOf(record.sku), 1);
        setSelectedRowKeys(newArray);
      } else {
        record.children.forEach((firstChild) => {
          if (firstChild.children) {
            firstChild.children.forEach((secondChild) => {
              newArray.splice(newArray.indexOf(secondChild.sku), 1);
            });
          } else {
            newArray.splice(newArray.indexOf(firstChild.sku), 1);
          }
        });
        setSelectedRowKeys(newArray);
      }
    }
  };

  const handleUpdateMrp = (newValue, record) => {
    const newData = [...modifiedDataList];
    const index = newData.findIndex((item) => record.sku === item.key);
    const today = moment().format("YYYY-MM-DD");
    if (index < 0) {
      newData.push({ key: record.sku, mrp: newValue, deliveryDate: today });
    } else {
      const date = newData[index].deliveryDate ? newData[index].deliveryDate : today;
      newData.splice(index, 1, { ...newData[index], mrp: newValue, deliveryDate: date });
    }

    let colorSum = 0;

    const styleTotalSumMrp = dataSku.reduce((total, elm) => {
      if (elm.stylename === record.stylename) {
        const elmMrp = newData.find((el) => el.key === elm.sku)?.mrp || 0;
        if (elm.color_description === record.color_description) {
          colorSum = colorSum + elmMrp;
        }
        const newTotal = total + elmMrp;
        return newTotal;
      } else {
        return total;
      }
    }, 0);

    const indexColor = newData.findIndex((item) => `${record.stylename}@@@${record.color_description}` === item.key);
    const indexStylename = newData.findIndex((item) => record.stylename === item.key);

    if (indexColor < 0) {
      newData.push({ key: `${record.stylename}@@@${record.color_description}`, mrp: colorSum, isParent: true });
    } else {
      newData.splice(indexColor, 1, { ...newData[indexColor], mrp: colorSum });
    }

    if (indexStylename < 0) {
      newData.push({ key: record.stylename, mrp: styleTotalSumMrp, isParent: true });
    } else {
      newData.splice(indexStylename, 1, { ...newData[indexStylename], mrp: styleTotalSumMrp });
    }

    setModifiedDataList(newData);

    if (!selectedRowKeys.includes(record.sku)) {
      handleChangeVendor(record.vendor_no);
      handleSetSelectedRowKeysSingle(record, true);
    }
  };

  const handleUpdatePrice = (newValue, record) => {
    const newData = [...modifiedDataList];
    const index = newData.findIndex((item) => record.sku === item.key);

    if (index < 0) {
      newData.push({ key: record.sku, price: newValue });
    } else {
      newData.splice(index, 1, { ...newData[index], price: newValue });
    }

    setModifiedDataList(newData);
  };

  const handleUpdateDiscount = (newValue, record) => {
    const newData = [...modifiedDataList];
    const index = newData.findIndex((item) => record.sku === item.key);

    if (index < 0) {
      newData.push({ key: record.sku, discount: newValue });
    } else {
      newData.splice(index, 1, { ...newData[index], discount: newValue });
    }

    setModifiedDataList(newData);
  };

  const handleSetDeliveryDate = (record, newDeliveryDate) => {
    const dateCorrectFormat = newDeliveryDate.format("YYYY-MM-DD");
    const newData = [...modifiedDataList];
    const index = newData.findIndex((item) => record.sku === item.key);

    if (index < 0) {
      newData.push({ key: record.sku, deliveryDate: dateCorrectFormat });
    } else {
      newData.splice(index, 1, { ...newData[index], deliveryDate: dateCorrectFormat });
    }

    setModifiedDataList(newData);

    if (!selectedRowKeys.includes(record.sku)) {
      handleChangeVendor(record.vendor_no);
      handleSetSelectedRowKeysSingle(record, true);
    }
  };

  return (
    <div className="PreparePO">
      <Space direction="vertical" style={{ width: "100%" }}>
        <Button icon={<ArrowLeftOutlined />} onClick={removeAllData}>
          Back
        </Button>
        <PoCards
          dataSku={dataSku}
          modifiedDataList={modifiedDataList}
          selectedRowKeys={selectedRowKeys}
          documentType={documentType}
          vendor={vendor}
          availableVendors={availableVendors}
          handleChangeVendor={handleChangeVendor}
          poGroup={selectedPoGroup}
          purchasingGroupList={purchasingGroupList}
          setSelectedPoGroup={setSelectedPoGroup}
          setDocumentType={handleChangeDocumentType}
          orderRef={orderRef}
          setOrderRef={setOrderRef}
          sapCredentials={sapCredentials}
          setSapCredentials={setSapCredentials}
          selectedGRProcTime={selectedGRProcTime}
          setSelectedGRProcTime={setSelectedGRProcTime}
        />
        <PoToolbar
          handleGlobalFilter={handleGlobalFilter}
          globalFilter={globalFilter}
          sapCredentials={sapCredentials}
          selectedRowKeys={selectedRowKeys}
          modifiedDataList={modifiedDataList}
          dataSku={dataSku}
          dataSkuSorted={dataSkuSorted}
          setSelectedRowKeys={setSelectedRowKeys}
          setSelectionFilter={setSelectionFilter}
          selectionFilter={selectionFilter}
          setModifiedDataList={setModifiedDataList}
          setShowColumnModal={setShowColumnModal}
          view={view}
          handleSetView={handleSetView}
          poGroup={selectedPoGroup}
          documentType={documentType}
          plant={plant}
          vendor={vendor}
          orderRef={orderRef}
          setOrderNumber={setOrderNumber}
          refreshNotifications={refreshNotifications}
          selectedGRProcTime={selectedGRProcTime}
        />
        <PoTables
          view={view}
          documentType={documentType}
          hiddenColumnsList={hiddenColumnsList}
          modifiedDataList={modifiedDataList}
          selectionFilter={selectionFilter}
          dataSku={dataSku}
          dataSkuSorted={dataSkuSorted}
          dataStyleSorted={dataStyleSorted}
          selectedRowKeys={selectedRowKeys}
          handleSetSelectedRowKeysSingle={handleSetSelectedRowKeysSingle}
          onCancelColumnSelection={onCancelColumnSelection}
          showColumnModal={showColumnModal}
          sortState={sortState}
          setSortState={setSortState}
          navigationListItem={navigationListItem}
          setNavigationListItem={setNavigationListItem}
          selectedRowParentKeys={selectedRowParentKeys}
          expandedRowKeys={expandedRowKeys}
          setExpandedRowKeys={setExpandedRowKeys}
          handleChangeVendor={handleChangeVendor}
          setModifiedDataList={setModifiedDataList}
          handleUpdateMrp={handleUpdateMrp}
          handleUpdatePrice={handleUpdatePrice}
          handleSetDeliveryDate={handleSetDeliveryDate}
          handleUpdateDiscount={handleUpdateDiscount}
          selectedGRProcTime={selectedGRProcTime}
        />
      </Space>
      <SuccessModal orderNumber={orderNumber} handleCloseModal={() => setOrderNumber()} />
      <BackTop visibilityHeight={700} />
    </div>
  );
};

PreparePo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default PreparePo;
