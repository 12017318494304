const AppControl = ({ userRoles, accessRoles, children }) => {
  if (!userRoles?.length) {
    return null;
  }

  const userHasRequiredRole = accessRoles?.some((role) => userRoles.includes(role));

  return userHasRequiredRole ? children : null;
};

export default AppControl;
