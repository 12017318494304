import { Item } from "../interfaces/Item.interface";

export const filterArrayKeyword = (array: Item[], filterWords: string): Item[] => {
  if (!filterWords) {
    return array;
  }

  const splitFilterWords: string[] = filterWords.split(";");

  return array.filter((item) => {
    const keys = Object.keys(item);
    return splitFilterWords.every((word) => {
      return keys.some((key) => {
        return item[key] ? item[key].toString().toLowerCase().includes(word.toLowerCase().trim()) : false;
      });
    });
  });
};

export const filterSelected = (array: Item[], selectedSkuList: string[]): Item[] =>
  filterOnSelectedSku(array, selectedSkuList, true);

export const filterNonSelected = (array: Item[], selectedSkuList: string[]): Item[] =>
  filterOnSelectedSku(array, selectedSkuList, false);

const filterOnSelectedSku = (array: Item[], selectedSkuList: string[], filterOnlySelected: boolean): Item[] => {
  if (!array?.length) {
    return [];
  }

  return array.filter((item) => {
    return filterOnlySelected ? selectedSkuList.includes(item.sku) : !selectedSkuList.includes(item.sku);
  });
};
