import React from "react";
import "./Welcome.page.scss";
import AppControl from "components/AppControl.component";
import { Card, Layout } from "antd";
import { Link } from "react-router-dom";
import { FileSearchOutlined, ContainerOutlined } from "@ant-design/icons";

const { Content } = Layout;

const WelcomePage = () => {
  return (
    <Content style={{ padding: "3em" }}>
      <div className="WelcomePage">
        <AppControl userRoles={["fjellsport-ks"]} accessRoles={["fjellsport-ks"]}>
          <Link to="/productsearch">
            <Card hoverable style={{ width: 250 }}>
              <FileSearchOutlined className="card__icon" /> Product Search
            </Card>
          </Link>
          <Link to="/purchaseorder">
            <Card hoverable style={{ width: 250 }}>
              <ContainerOutlined className="card__icon" /> Purchase Order
            </Card>
          </Link>
        </AppControl>
      </div>
    </Content>
  );
};

export default WelcomePage;
