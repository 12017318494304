import React from "react";
import { InputNumber, Tag, Tooltip } from "antd";
import { addTagsToProductName } from "./PoUtil";
import { getPreference } from "utils/LocalStorage.util";
import moment from "moment";
import { Column } from "react-base-table";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { calculatePutawayDate } from "utils/Date.util";
import { isFeatureAvailable } from "components/Feature/Feature.component";

let grProcTime;

const isColor = (strColor) => {
  var s = new Option().style;
  s.color = strColor;
  return s.color === strColor;
};

export const defaultColumns = (documentType, hiddenColumnsList) => [
  {
    key: "sku",
    dataKey: "sku",
    title: "SKU",
    width: 100,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("SKU"),
    frozen: Column.FrozenDirection.LEFT,
  },
  {
    key: "item_no",
    dataKey: "item_no",
    title: "Item no",
    width: 150,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("Item no"),
  },
  {
    key: "variant_code",
    dataKey: "variant_code",
    title: "Variant code",
    width: 150,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("Variant code"),
  },
  {
    key: "MFRNR",
    dataKey: "MFRNR",
    title: "Brand",
    width: 150,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("Brand"),
  },
  {
    key: "stylename",
    dataKey: "stylename",
    title: "Name",
    width: 200,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("Name"),
    cellRenderer: ({ rowData }) => addTagsToProductName(rowData),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Stylename">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "color_description",
    dataKey: "color_description",
    title: "Color",
    width: 150,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("Color"),
  },
  {
    key: "size_description",
    dataKey: "size_description",
    title: "Size",
    width: 80,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("Size"),
  },
  {
    key: "vendor_sku",
    dataKey: "vendor_sku",
    title: "Manu Part No.",
    width: 150,
    sortable: true,
    resizable: true,
    hidden: hiddenColumnsList.includes("Manu Part No."),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Manufacturer Part number">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "OMENG",
    dataKey: "OMENG",
    title: "Back",
    width: 100,
    sortable: true,
    resizable: true,
    hidden: hiddenColumnsList.includes("Back"),
  },
  {
    key: "mrp",
    title: "MRP",
    width: 100,
    sortable: true,
    resizable: false,
    cellRenderer: ({ rowData, column }) => {
      const handleMrpChange = (newValue) => column.handleUpdateMrp(newValue, rowData);
      const mrpData = column.modifiedDataList.find((item) =>
        rowData.sku ? item.key === rowData.sku : item.key === rowData.key
      )?.mrp;

      return rowData.sku ? <InputNumber min={0} onChange={handleMrpChange} value={mrpData || 0} /> : mrpData || 0;
    },
  },
  {
    title: "Delivery date",
    key: "deliveryDate",
    width: 150,
    sortable: true,
    resizable: false,
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Ship/Delivery date">
        {column.title}
      </Tooltip>
    ),
    cellRenderer: ({ rowData, column }) => {
      const deliveryDate = column.modifiedDataList.find((item) => rowData.sku === item.key)?.deliveryDate;
      return (
        rowData?.sku && (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              disableToolbar
              disablePast
              autoOk
              variant="inline"
              format={getPreference("dateformat")}
              value={deliveryDate ? moment(deliveryDate) : null}
              onChange={(date) => column.handleSetDeliveryDate(rowData, date)}
            />
          </MuiPickersUtilsProvider>
        )
      );
    },
  },
  {
    title: "Putaway date",
    key: "putaway_date",
    width: 130,
    sortable: true,
    resizable: false,
    hidden: hiddenColumnsList.includes("Putaway date"),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="">
        {column.title}
      </Tooltip>
    ),
    cellRenderer: ({ rowData, column }) => {
      const deliveryDate = column.modifiedDataList.find((item) => rowData.sku === item.key)?.deliveryDate;
      return (
        <span>
          {deliveryDate && calculatePutawayDate(deliveryDate, rowData.lead_days + grProcTime, getPreference("dateformat"))}
        </span>
      );
    },
  },
  {
    key: "availability",
    dataKey: "availability",
    title: "Availability",
    sortable: true,
    width: 100,
    resizable: true,
    hidden: hiddenColumnsList.includes("Availability"),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Availability Fjellsport">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "pfsitem_status_sku",
    dataKey: "pfsitem_status_sku",
    title: "pfsitem_status_sku",
    sortable: true,
    width: 150,
    resizable: true,
    hidden: hiddenColumnsList.includes("pfsitem_status_sku"),
  },
  {
    key: "pfsseason",
    dataKey: "pfsseason",
    title: "PO Season",
    sortable: true,
    width: 120,
    resizable: true,
    hidden: hiddenColumnsList.includes("PO Season"),
  },
  {
    key: "is_in_stock",
    dataKey: "is_in_stock",
    title: "Stock",
    width: 100,
    sortable: true,
    resizable: true,
    hidden: hiddenColumnsList.includes("Stock"),
  },
  {
    key: "ZTOTP",
    dataKey: "ZTOTP",
    title: "Ava-qty",
    width: 100,
    sortable: true,
    resizable: true,
    hidden: hiddenColumnsList.includes("Ava-qty"),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Available Quantity">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "OBMNG",
    dataKey: "OBMNG",
    title: "OP",
    width: 100,
    sortable: true,
    resizable: true,
    hidden: hiddenColumnsList.includes("OP"),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Open Purchase Orders">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "z_invrpt_qty",
    dataKey: "z_invrpt_qty",
    title: "Inv-qty",
    sortable: true,
    width: 100,
    resizable: true,
    hidden: hiddenColumnsList.includes("Inv-qty"),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Inventory Quantity">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "z_invrpt_incoming_date",
    dataKey: "z_invrpt_incoming_date",
    title: "Inv in date",
    width: 100,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("Inv in date"),

    cellRenderer: ({ rowData, cellData }) =>
      cellData ? (
        <div>{`[${rowData.z_invrpt_incoming_qty}] ${moment(cellData).format(getPreference("dateformat"))}`}</div>
      ) : null,
  },
  {
    key: "tax_code",
    dataKey: "tax_code",
    title: "Tax",
    width: 100,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("Tax code"),
  },
  {
    key: "currency_code",
    dataKey: "currency_code",
    title: "Currency",
    width: 100,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("Currency"),
  },
  {
    key: "price",
    dataKey: "price",
    title: "Best price",
    width: 100,
    resizable: true,
    sortable: true,
    hidden: documentType === "ZPRE",
    cellRenderer: ({ rowData, column, cellData }) => {
      const handlePriceChange = (newValue) => column.handleUpdatePrice(newValue, rowData);
      const price = column.modifiedDataList.find((item) =>
        rowData.sku ? item.key === rowData.sku : item.key === rowData.key
      )?.price;

      if (isFeatureAvailable("edit-price-and-discount")) {
        return rowData.sku && <InputNumber min={0} onChange={handlePriceChange} value={price || cellData} />;
      } else {
        return <span>{cellData}</span>;
      }
    },
  },
  {
    key: "supplementary_discount_pct",
    dataKey: "supplementary_discount_pct",
    title: "Discount",
    width: 100,
    resizable: true,
    sortable: true,
    hidden: documentType === "ZPRE",
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="A negative number between -99 and 0">
        {column.title}
      </Tooltip>
    ),
    cellRenderer: ({ rowData, column, cellData }) => {
      const handleDiscountChange = (newValue) => column.handleUpdateDiscount(newValue, rowData);
      const discount = column.modifiedDataList.find((item) =>
        rowData.sku ? item.key === rowData.sku : item.key === rowData.key
      )?.discount;

      if (isFeatureAvailable("edit-price-and-discount")) {
        return rowData.sku && <InputNumber min={-99} max={0} onChange={handleDiscountChange} value={discount || cellData} />;
      } else {
        return <span>{cellData}</span>;
      }
    },
  },
  {
    key: "preorder_price",
    dataKey: "preorder_price",
    title: "Best price",
    width: 100,
    resizable: true,
    sortable: true,
    hidden: documentType !== "ZPRE",
    cellRenderer: ({ rowData, column, cellData }) => {
      const handlePriceChange = (newValue) => column.handleUpdatePrice(newValue, rowData);
      const price = column.modifiedDataList.find((item) =>
        rowData.sku ? item.key === rowData.sku : item.key === rowData.key
      )?.price;

      if (isFeatureAvailable("edit-price-and-discount")) {
        return rowData.sku && <InputNumber min={0} onChange={handlePriceChange} value={price || cellData} />;
      } else {
        return <span>{cellData}</span>;
      }
    },
  },
  {
    key: "preorder_discount_pct",
    dataKey: "preorder_discount_pct",
    title: "Discount",
    width: 100,
    resizable: true,
    sortable: true,
    hidden: documentType !== "ZPRE",
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="A negative number between -99 and 0">
        {column.title}
      </Tooltip>
    ),
    cellRenderer: ({ rowData, column, cellData }) => {
      const handleDiscountChange = (newValue) => column.handleUpdateDiscount(newValue, rowData);
      const discount = column.modifiedDataList.find((item) =>
        rowData.sku ? item.key === rowData.sku : item.key === rowData.key
      )?.discount;

      if (isFeatureAvailable("edit-price-and-discount")) {
        return rowData.sku && <InputNumber min={-99} max={0} onChange={handleDiscountChange} value={discount || cellData} />;
      } else {
        return <span>{cellData}</span>;
      }
    },
  },
  {
    key: "DUEPO",
    dataKey: "DUEPO",
    title: "Due PO",
    width: 100,
    sortable: true,
    resizable: true,
    hidden: hiddenColumnsList.includes("Due PO"),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Number of Due PO items">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "vendor_no",
    title: "VendName B",
    width: 200,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("VendName B"),
    cellRenderer: ({ rowData }) => (
      <span>{rowData.vendor_no ? `${rowData.vendor_no} ${rowData.NAME1_MIN}` : rowData.NAME1_MIN}</span>
    ),
  },
  {
    key: "ZMENG1",
    dataKey: "ZMENG1",
    title: "S1",
    width: 80,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("S1"),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Sales last week">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "ZMENG2",
    dataKey: "ZMENG2",
    title: "S2",
    width: 80,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("S2"),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Sales last two weeks">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "ZMENG3",
    dataKey: "ZMENG3",
    title: "S3",
    width: 80,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("S3"),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Sales last three weeks">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "ZMENG4",
    dataKey: "ZMENG4",
    title: "S4",
    width: 80,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("S4"),
    headerRenderer: ({ column }) => (
      <Tooltip mouseEnterDelay="0.5" title="Sales last four weeks">
        {column.title}
      </Tooltip>
    ),
  },
  {
    key: "DMBTR_PO",
    dataKey: "DMBTR_PO",
    title: "Open PO val",
    width: 120,
    sortable: true,
    resizable: true,
    hidden: hiddenColumnsList.includes("Open PO val"),
  },
  {
    title: "GTIN",
    dataKey: "EAN11",
    key: "EAN11",
    width: 150,
    resizable: true,
    sortable: true,
    hidden: hiddenColumnsList.includes("GTIN"),
  },
];

export const skuColumns = (documentType, selectedGRProcTime, hiddenColumnsList) => {
  grProcTime = selectedGRProcTime;
  return defaultColumns(documentType, hiddenColumnsList);
};

export const styleColumns = (documentType, selectedGRProcTime, hiddenColumnsList) => {
  grProcTime = selectedGRProcTime;
  return [
    {
      key: "key",
      dataKey: "key",
      title: "",
      width: 240,
      resizable: true,
      sortable: true,
      frozen: Column.FrozenDirection.LEFT,
      cellRenderer: ({ rowData }) =>
        rowData.sku ? (
          <Tag>{rowData.size_description ? rowData.size_description : "One Size"}</Tag>
        ) : rowData.key.includes("@@@") ? (
          <Tag
            color={
              rowData.size_description && isColor(rowData.size_description.replace(/\s/g, "").toLowerCase())
                ? rowData.size_description.replace(/\s/g, "").toLowerCase()
                : ""
            }
          >
            {rowData.size_description}
          </Tag>
        ) : (
          <Tooltip title={rowData.size_description}>{rowData.size_description}</Tooltip>
        ),
    },
  ].concat(defaultColumns(documentType, hiddenColumnsList));
};
