import React from "react";
import { Modal, Button, Row, Col } from "antd";
import { deleteLocalStorageItem, setLocalStorageItem } from "utils/LocalStorage.util";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";

const NotificationModal = ({ notificationsModal, setNotificationsModal }) => {
  const handleCancel = () => {
    setNotificationsModal({ ...notificationsModal, visible: false });
  };

  const handleClear = () => {
    deleteLocalStorageItem("notifications");
    setNotificationsModal({ visible: false, notifications: null });
  };

  const deleteNotification = (id) => {
    const filteredNotifications = notificationsModal?.notifications?.filter((notification) => notification.id !== id);
    setLocalStorageItem("notifications", JSON.stringify(filteredNotifications));
    setNotificationsModal({ visible: true, notifications: filteredNotifications });
  };

  const colors = { info: "#91d5ff", warning: "#ffe58f", error: "#ffccc7", success: "#b7eb8f" };

  return (
    <Modal
      visible={notificationsModal?.visible}
      title="Notifications"
      onCancel={handleCancel}
      footer={[
        <Button key="back" type="primary" onClick={handleClear} disabled={!notificationsModal?.notifications?.length}>
          Clear all
        </Button>,
        <Button key="submit" onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >
      {notificationsModal?.notifications?.length ? (
        notificationsModal?.notifications?.map((item) => {
          return (
            <Row align="middle" key={item.id}>
              <Col span={22}>
                <span
                  style={{
                    display: "block",
                    marginBottom: "1em",
                    borderLeft: "3px solid " + colors[item.type],
                    padding: "0.6em",
                  }}
                >
                  <span style={{ fontSize: "0.9em", display: "block" }}>
                    {moment(item.date).fromNow()} - {item.type}
                  </span>
                  {item.message}
                </span>
              </Col>
              <Col span={1}>
                <Button shape="circle" icon={<DeleteOutlined />} onClick={() => deleteNotification(item.id)} />
              </Col>
            </Row>
          );
        })
      ) : (
        <p>You have no notifications 🙌</p>
      )}
    </Modal>
  );
};

export default NotificationModal;
