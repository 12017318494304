export const setLocalStorageItem = (key: string, data: string): void => {
  localStorage.setItem(key, data);
};

export const getLocalStorageItem = (key: string): string => {
  const localItem = localStorage.getItem(key);
  return localItem ? localItem : "";
};

export const deleteLocalStorageItem = (key: string): void => {
  localStorage.removeItem(key);
};

export const updatePreferenceObj = (key: string, value: string): void => {
  const storedPreferences = getLocalStorageItem("preferences");

  if (storedPreferences) {
    let newPreferenceObj = JSON.parse(storedPreferences);
    newPreferenceObj[key] = value;
    setLocalStorageItem("preferences", JSON.stringify(newPreferenceObj));
  }
};

export const getPreference = (key: string): string => {
  const storedPreferences = getLocalStorageItem("preferences");
  return storedPreferences ? JSON.parse(storedPreferences)[key] : "";
};

export const getTablePageSize = (): number => {
  const defaultPageSize = getPreference("tablePageSize");
  return defaultPageSize ? parseInt(defaultPageSize) : 10;
};
