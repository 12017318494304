// css
import "./ProductSearch.page.scss";

// 3rd party libs
import { AutoComplete, Empty, Input, Popover, Tooltip, Button, Menu } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { columns } from "./ProductSearchColumns";
import { FetchLookupData, FetchRecentData, FetchProductSearch, SyncItem } from "services/Pim.service";
import SelectionBaseTable from "components/Table/SelectionBaseTable/SelectionBaseTable.component";
import { PushNotification } from "utils/pushNotification.util";
import { sortByKey } from "utils/Sort.util";
import ContextMenu, { openContextMenu } from "components/ContextMenu/ContextMenu.component";
import { getLocalStorageItem, setLocalStorageItem } from "utils/LocalStorage.util";
import ColumnsModal from "components/Modal/ColumnsModal.component";
import { sortByOrderInArray } from "utils/Sort.util";

const { Search } = Input;

const ProductSearch = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchResults, setSearchResults] = useState();
  const [searchHistory, setSearchHistory] = useState(
    getLocalStorageItem("searchHistory") ? JSON.parse(getLocalStorageItem("searchHistory")) : []
  );
  const [optionFilter, setOptionFilter] = useState("");
  const [items, setItems] = useState([]);
  const [lookups, setLookups] = useState([]);
  const [sortedBy, setSortedBy] = useState({});
  const [tableSize, setTableSize] = useState({
    width: document.body.clientWidth - 60,
    height: document.body.clientHeight - 150,
  });
  const [contextMenu, setContextMenu] = useState({
    selectedRows: selectedRows,
    visible: false,
    x: 0,
    y: 0,
  });
  const [showColumnModal, setShowColumnModal] = useState(false);
  const [hiddenColumnsList, setHiddenColumnsList] = useState(
    getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns")).productsearch
      ? JSON.parse(getLocalStorageItem("hidden_columns")).productsearch
      : []
  );

  const history = useHistory();

  const searchForItems = (i) => {
    FetchProductSearch(i)
      .then((response) => setSearchResults(response.data))
      .catch(() => PushNotification("error", "Cannot fetch data", "Cannot fetch search results", false));

    addSearchToHistory(i);
  };

  useEffect(() => {
    const hidden_colums = getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns"));
    !hidden_colums.productsearch &&
      setLocalStorageItem("hidden_columns", JSON.stringify({ ...hidden_colums, productsearch: [] }));

    const columns_order = getLocalStorageItem("columns_order") && JSON.parse(getLocalStorageItem("columns_order"));
    !columns_order.productsearch &&
      setLocalStorageItem("columns_order", JSON.stringify({ ...columns_order, productsearch: [] }));

    FetchRecentData()
      .then(({ data }) => setItems(data))
      .catch(() => PushNotification("error", "Cannot fetch data", "Cannot fetch recent data", false));

    FetchLookupData("pim")
      .then(({ data }) => setLookups(data.lookups))
      .catch(() => PushNotification("error", "Cannot fetch data", "Cannot fetch lookup data", false));

    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onWindowResize = () =>
    setTableSize({ width: document.body.clientWidth - 60, height: document.body.clientHeight - 150 });

  const actionMenu = (
    <Menu mode="vertical" style={{ borderRight: 0 }}>
      <Menu.Item
        onClick={() => selectedRows.forEach((item) => syncItem(item.item_no))}
        key="1"
        disabled={selectedRows.length < 1}
      >
        Sync {selectedRows.length} items
      </Menu.Item>
      <Menu.Item onClick={() => setShowColumnModal(true)} key="2">
        Configure table columns
      </Menu.Item>
    </Menu>
  );

  const syncItem = (item_no) => {
    SyncItem(item_no).then(
      () => PushNotification("success", "Synced item", `Item ${item_no} was synced!`, true),
      () => PushNotification("error", "Unable to sync", `Item ${item_no} could not be synced!`, true)
    );
  };

  const appendDataToColumns = useMemo(() => {
    const goToPage = (path) => history.push(path);
    return sortByOrderInArray(
      columns(goToPage, hiddenColumnsList),
      JSON.parse(getLocalStorageItem("columns_order")).productsearch,
      "title"
    ).map((column) => ({
      ...column,
      lookups,
    }));
  }, [lookups, history, hiddenColumnsList]);

  const handleScroll = () => {
    if (contextMenu.visible) {
      setContextMenu({ ...contextMenu, visible: false });
    }
  };

  const addSearchToHistory = (i) => {
    const searchTerm = i.toLowerCase().trim();
    if (getLocalStorageItem("searchHistory")) {
      let searchHistory = JSON.parse(getLocalStorageItem("searchHistory"));
      searchHistory.length >= 5 && searchHistory.shift();
      if (!searchHistory.some((e) => e.value === searchTerm) && i !== "") {
        searchHistory.push({ value: searchTerm });
      }
      setLocalStorageItem("searchHistory", JSON.stringify(searchHistory));
      setSearchHistory(searchHistory);
    } else {
      setLocalStorageItem("searchHistory", JSON.stringify([{ value: searchTerm }]));
      setSearchHistory([{ value: searchTerm }]);
    }
  };

  const handleRowSelection = (e) => {
    selectedRows.some((i) => i.item_no === e.item_no)
      ? setSelectedRows(selectedRows.filter((i) => i.item_no !== e.item_no))
      : setSelectedRows([...selectedRows, e]);
  };

  const onCancelColumnSelection = () => {
    setShowColumnModal(false);
    setHiddenColumnsList(JSON.parse(getLocalStorageItem("hidden_columns")).productsearch);
  };

  return (
    <div style={{ margin: "2em" }}>
      <div style={{ display: "grid", gridTemplateColumns: "auto 40px" }}>
        <AutoComplete
          style={{ width: "100%", marginBottom: "1.5em" }}
          onChange={(e) => setOptionFilter(e)}
          options={searchHistory?.reverse().filter((item) => item?.value.includes(optionFilter.toLowerCase()))}
        >
          <Search onSearch={(i) => searchForItems(i)} placeholder="Search for products" allowClear />
        </AutoComplete>
        <Popover placement="bottomLeft" content={actionMenu} trigger="click">
          <Tooltip title="More actions">
            <Button icon={<MoreOutlined />} style={{ float: "right" }} />
          </Tooltip>
        </Popover>
      </div>
      <SelectionBaseTable
        rowKey="item_no"
        rowHeight={40}
        headerHeight={30}
        width={tableSize.width}
        height={350}
        showSelectionColumn
        onSelectionChange={handleRowSelection}
        onScroll={handleScroll}
        maxHeight={tableSize.height}
        columns={appendDataToColumns}
        data={sortByKey(searchResults ? searchResults : items, sortedBy.key, sortedBy.order)}
        expandedRowKeys={[]}
        rowEventHandlers={{
          onContextMenu: ({ rowData, event }) =>
            openContextMenu(rowData, contextMenu, setContextMenu, selectedRows, event, false),
        }}
        sortBy={sortedBy}
        onColumnSort={({ key, order }) => setSortedBy({ key, order })}
        emptyRenderer={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>No results was found. Table is empty.</Empty>}
      />
      <ContextMenu {...contextMenu} />
      {showColumnModal && (
        <ColumnsModal
          visible
          onCancel={onCancelColumnSelection}
          localStorageId="productsearch"
          columns={[...new Set(appendDataToColumns.map((el) => el.title))].filter((el) => el)}
          disabledSkuList={["Product Id"]}
        />
      )}
    </div>
  );
};

export default ProductSearch;
