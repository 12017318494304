import { Tag, Tooltip } from "antd";

export const isBack = (ZTOTP) => ZTOTP < 0;

export const isNoos = (abc, availability) => abc === "A" && availability === "in-stock";

export const addTagsToProductName = ({ ZTOTP, abc, availability, stylename }) => {
  const back = isBack(ZTOTP);
  const noos = isNoos(abc, availability);

  return (
    <span>
      {noos && <Tag color="green">NOOS</Tag>}
      {back && <Tag color="red">Back</Tag>}
      <Tooltip title={stylename} mouseEnterDelay="0.5">
        {stylename}
      </Tooltip>
    </span>
  );
};
