const features = [
  {
    name: "console-log-debugger",
    description: "This will enable console logging of api responses++",
    active: false,
  },
  { name: "edit-price-and-discount", description: "Make price and discount column editable", active: false },
  {
    name: "po-execute-selections",
    description: "Add radiobuttons on prepare po with options full and optimized",
    active: false,
  },
  {
    name: "productcomments",
    description: "Add Product comments tab in product card",
    active: false,
  },
  {
    name: "productsearch_save_data",
    description: "Add tooltip of savedata in product search",
    active: false,
  },
  {
    name: "description_translation",
    description: "",
    active: false,
  },
  {
    name: "version_checking_disabled",
    description: "Disable version checking (only activate on localhost)",
    active: false,
  },
];

export const initFeatures = () => {
  let flags = localStorage.getItem("flags");

  if (!flags) {
    localStorage.setItem("flags", JSON.stringify(features));
  } else {
    const filteredFlags = JSON.parse(flags).filter((flag) => features.some((feat) => feat.name === flag.name));

    features.forEach((feat) => {
      const isNewFeature = !filteredFlags.some((flag) => flag.name === feat.name);
      isNewFeature && filteredFlags.push(feat);
    });

    localStorage.setItem("flags", JSON.stringify(filteredFlags));
  }
};

export const isFeatureAvailable = (name) => {
  const features = localStorage.getItem("flags");
  if (!features) return;

  const feature = JSON.parse(features).find((feature) => feature.name === name);
  return feature && feature.active;
};

const Feature = ({ name, children }) => {
  const features = localStorage.getItem("flags");
  if (!features) return;

  const feature = JSON.parse(features).find((feature) => feature.name === name);

  if (feature && feature.active) {
    return children;
  }

  return null;
};

export default Feature;
