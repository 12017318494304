import React, { useState } from "react";
import { Row, Col, Input, Space, Dropdown, Radio, Button, DatePicker, notification, Tooltip } from "antd";
import DropdownMenu from "./DropdownMenu.component";
import CreateButtonPo from "./CreateButtonPo.component";
import { DownOutlined, QuestionCircleOutlined, TableOutlined } from "@ant-design/icons";
import { isBack, isNoos } from "../PoUtil";
import { dateInThePast } from "utils/Date.util";
import { getPreference } from "utils/LocalStorage.util";
import { View } from "utils/Enums.util";
import ConfirmPOModal from "./ConfirmPoModal.component";
import { createPo } from "services/Po.service";
import { PushNotification } from "utils/pushNotification.util";

const PoToolbar = ({
  handleGlobalFilter,
  globalFilter,
  sapCredentials,
  selectedRowKeys,
  modifiedDataList,
  dataSku,
  setSelectedRowKeys,
  setSelectionFilter,
  selectionFilter,
  setModifiedDataList,
  setShowColumnModal,
  view,
  handleSetView,
  poGroup,
  documentType,
  plant,
  vendor,
  orderRef,
  setOrderNumber,
  refreshNotifications,
  selectedGRProcTime,
}) => {
  const [showPoConfirmationModal, setShowPoConfirmationModal] = useState(false);
  const [creatingPo, setCreatingPo] = useState();

  const handleCreatePoButtonPressed = () => {
    const skuWithMrpAndNotSelected = modifiedDataList.some(
      (obj) => !selectedRowKeys.includes(obj.key) && !obj.isParent && (obj.mrp > 0 || obj.deliveryDate)
    );

    if (skuWithMrpAndNotSelected) {
      setShowPoConfirmationModal(true);
    } else {
      handleCreatePo();
    }
  };

  const handleCreatePo = () => {
    setShowPoConfirmationModal(false);
    setCreatingPo(true);
    const selectedItemsReadyForPo = dataSku
      .filter((item) => {
        const mrpObject = modifiedDataList.find((m) => m.key === item.sku);
        return selectedRowKeys.includes(item.sku) && mrpObject?.mrp > 0;
      })
      .map((item) => {
        const mrpObject = modifiedDataList.find((m) => m.key === item.sku);
        const price = mrpObject.price ? mrpObject.price : documentType === "ZPRE" ? item.preorder_price : item.price;
        const discount = mrpObject.discount
          ? mrpObject.discount
          : documentType === "ZPRE"
          ? item.preorder_discount_pct
          : item.supplementary_discount_pct;
        return {
          sku: item.sku,
          quantity: mrpObject.mrp,
          currency: item.currency_code,
          lgort: plant, //TODO: plant og location
          delivery_date: mrpObject.deliveryDate,
          gr_proc_time: selectedGRProcTime,
          price,
          discount,
        };
      });

    const dateformat = getPreference("dateformat");

    createPo(
      vendor,
      poGroup,
      plant,
      selectedItemsReadyForPo,
      sapCredentials.user,
      sapCredentials.password,
      documentType,
      orderRef,
      dateformat
    )
      .then(({ data }) => {
        if (data?.response[0] === "ok") {
          setCreatingPo(false);
          setOrderNumber(data.response[1]);
          setSelectedRowKeys([]);
          setModifiedDataList([]);
          PushNotification("success", "Created PO", "Created PO with id: " + data.response[1], false);
          refreshNotifications();
        } else {
          setCreatingPo(false);
          notification["warning"]({
            message: "Unable to create PO",
            description: "We were unable to create the PO. Please check your data before trying again.",
          });
        }
      })
      .catch(() => {
        setCreatingPo(false);
        notification["warning"]({
          message: "Unable to create PO",
          description: "We were unable to create the PO. Please check your data before trying again.",
        });
      });
  };

  const selectAll = () => {
    const selectedRowsSet = new Set(selectedRowKeys);
    dataSku.forEach((d) => selectedRowsSet.add(d.sku));
    setSelectedRowKeys(Array.from(selectedRowsSet));
  };

  const selectAllBack = () => {
    const selectedRowsSet = new Set(selectedRowKeys);
    dataSku.forEach((d) => isBack(d.ZTOTP) && selectedRowsSet.add(d.sku));
    setSelectedRowKeys(Array.from(selectedRowsSet));
  };

  const selectAllNoos = () => {
    const selectedRowsSet = new Set(selectedRowKeys);
    dataSku.forEach((d) => isNoos(d.abc, d.availability) && selectedRowsSet.add(d.sku));
    setSelectedRowKeys(Array.from(selectedRowsSet));
  };

  const updateDeliveryDateAllSelected = (deliveryDate) => {
    const newMrpDeliveryDateList = [...modifiedDataList];

    selectedRowKeys.forEach((sku) => {
      const isAllreadyInList = newMrpDeliveryDateList.some((o) => o.key === sku);
      if (!isAllreadyInList) {
        newMrpDeliveryDateList.push({ key: sku });
      }
    });

    setModifiedDataList(newMrpDeliveryDateList.map((obj) => ({ ...obj, deliveryDate: deliveryDate.format("YYYY-MM-DD") })));
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Row gutter={16}>
        <Col span="23">
          <Input placeholder="Filter items" allowClear onChange={handleGlobalFilter} value={globalFilter} />
        </Col>
        <Col span="1">
          <Tooltip title="Filter on multiple columns by separating words with semicolon">
            <Button shape="circle" icon={<QuestionCircleOutlined />} />
          </Tooltip>
        </Col>
      </Row>
      <Row justify="space-between" align="center">
        <Col span={4}>
          <CreateButtonPo
            sapCredentials={sapCredentials}
            selectedRowKeys={selectedRowKeys}
            modifiedDataList={modifiedDataList}
            handleCreatePoButtonPressed={handleCreatePoButtonPressed}
            creatingPo={creatingPo}
            poGroup={poGroup}
          />
        </Col>
        <Space align="center">
          <Dropdown
            overlay={
              <DropdownMenu
                selectAll={selectAll}
                setSelectedRowKeys={setSelectedRowKeys}
                selectAllBack={selectAllBack}
                selectAllNoos={selectAllNoos}
              />
            }
            trigger={["click"]}
          >
            <Button type="link" onClick={(e) => e.preventDefault()}>
              Quick select <DownOutlined />
            </Button>
          </Dropdown>
          <Radio.Group onChange={(e) => setSelectionFilter(e.target.value)} value={selectionFilter}>
            <Radio.Button value="all">All</Radio.Button>
            <Radio.Button value="selected">Selected</Radio.Button>
          </Radio.Group>
          <DatePicker
            onChange={(deliveryDate) => {
              updateDeliveryDateAllSelected(deliveryDate);
            }}
            showToday={false}
            placeholder="Update dates"
            value=""
            disabledDate={dateInThePast}
            format={getPreference("dateformat")}
            allowClear={false}
          />
          <Button onClick={() => setShowColumnModal(true)}>
            <TableOutlined />
          </Button>
        </Space>
        <Radio.Group
          options={[
            { label: "Style", value: View.STYLE },
            { label: "SKU", value: View.SKU },
          ]}
          onChange={handleSetView}
          value={view}
          optionType="button"
          buttonStyle="solid"
        />
      </Row>
      <ConfirmPOModal
        showPoConfirmationModal={showPoConfirmationModal}
        handleCreatePo={handleCreatePo}
        modifiedDataList={modifiedDataList}
        selectedRowKeys={selectedRowKeys}
        handleCloseModal={() => setShowPoConfirmationModal(false)}
      />
    </Space>
  );
};

export default PoToolbar;
