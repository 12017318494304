import React, { useState, useEffect } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "utils/LocalStorage.util";
import { List, Typography, Switch, Row, Col } from "antd";

const FeatureDashboard = () => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const localStorageFeatures = getLocalStorageItem("flags");
    setFeatures(JSON.parse(localStorageFeatures));
  }, []);

  const handleToggle = (name, checked) => {
    const newFeatures = [...features].map((feature) => {
      if (feature.name === name) {
        feature.active = checked;
      }
      return feature;
    });
    setFeatures(newFeatures);
    setLocalStorageItem("flags", JSON.stringify(newFeatures));
  };

  return (
    <Row>
      <Col span={12} offset={6}>
        <Typography.Title level={2}>Toggle features</Typography.Title>
        <List
          bordered
          itemLayout="horizontal"
          dataSource={features}
          renderItem={(feature) => (
            <List.Item
              extra={
                <Switch
                  checked={feature.active}
                  onChange={(checked) => {
                    handleToggle(feature.name, checked);
                  }}
                />
              }
            >
              <List.Item.Meta title={feature.name} description={feature.description} />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default FeatureDashboard;
