import "./ProductCard.page.scss";

import { Card, Skeleton, Button, Popover, Menu, Tooltip, Row, Space, BackTop } from "antd";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ArrowLeftOutlined, MoreOutlined, SaveOutlined } from "@ant-design/icons";

import Info from "../Info/Info.page";
import InfoComponent from "../Info/InfoComponent.page";
import ProductPlant from "components/ProductPlant/ProductPlant.component";
import PurchasingInfoRecord from "components/PurchasingInfoRecord/PurchasingInfoRecord.component";
import ProductVariant from "components/ProductVariant/ProductVariant.component";
import { FetchProductData, FetchLookupData, SyncItem, SaveItemChanges } from "services/Pim.service";
import { PushNotification } from "utils/pushNotification.util";
import Purchasing from "components/Purchasing/Purchasing.component";
import ProductComments from "components/ProductComments.component";
import { isFeatureAvailable } from "components/Feature/Feature.component";

const TabList = [
  {
    key: "info",
    tab: "Info",
  },
  {
    key: "component",
    tab: "Component",
  },
  {
    key: "plant",
    tab: "Plant",
  },
  {
    key: "variant",
    tab: "Variant",
  },
  {
    key: "purchasing",
    tab: "Purchasing",
  },
  {
    key: "purchasinginforecord",
    tab: "Purchasing Info Record",
  },
];

const ProductCard = () => {
  const { tab, item_no } = useParams();
  const [loadingData, setLoadingData] = useState({ items: false, lookups: false });
  const [productData, setProductData] = useState();
  const [lookupData, setLookupData] = useState({});
  const [userChanges, setUserChanges] = useState({});
  const [selectedTab, setSelectedTab] = useState({
    noTitleKey: tab,
  });

  const history = useHistory();

  isFeatureAvailable("productcomments") &&
    !TabList.some((tab) => tab.key === "productcomments") &&
    TabList.push({
      key: "productcomments",
      tab: "Product Comments",
    });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => {
    setLoadingData({ items: true, lookups: true });

    FetchProductData(item_no)
      .then(
        ({ data }) => setProductData(data),
        () => PushNotification("error", "Cannot fetch data", "Cannot fetch product data", false)
      )
      .then(() => setLoadingData({ ...loadingData, items: false }));

    FetchLookupData("pim")
      .then(
        ({ data }) => setLookupData(data?.lookups),
        () => PushNotification("error", "Cannot fetch data", "Cannot fetch lookup data", false)
      )
      .then(() => setLoadingData({ ...loadingData, lookups: false }));
  };

  const syncItem = (item_no) => {
    SyncItem(item_no).then(
      () => PushNotification("success", "Synced item", `Item ${item_no} was synced!`, true),
      () => PushNotification("error", "Unable to sync", `Item ${item_no} could not be synced!`, true)
    );
  };

  const tabContent = (tab) => {
    return (
      productData != null &&
      {
        info: <Info data={productData} lookups={lookupData} userChanges={userChanges} setUserChanges={setUserChanges} />,
        component: (
          <InfoComponent data={productData} lookups={lookupData} userChanges={userChanges} setUserChanges={setUserChanges} />
        ),
        plant: (
          <ProductPlant
            data={productData.item_sku_plant}
            lookups={lookupData}
            userChanges={userChanges}
            setUserChanges={setUserChanges}
          />
        ),
        purchasinginforecord: <PurchasingInfoRecord data={productData.item_sku_pir} />,
        purchasing: <Purchasing data={productData} lookups={lookupData} />,
        variant: (
          <ProductVariant
            data={productData.item_sku}
            lookups={lookupData}
            userChanges={userChanges}
            setUserChanges={setUserChanges}
          />
        ),
        productcomments: <ProductComments />,
      }[tab]
    );
  };

  const onTabChange = (key) => {
    history.replace({ pathname: `/productcard/${item_no}/${key}` });
    setSelectedTab({ noTitleKey: key });
  };

  const actionMenu = (
    <Menu mode="vertical" style={{ borderRight: 0 }}>
      <Menu.Item onClick={() => syncItem(item_no)} key="1">
        Sync
      </Menu.Item>
    </Menu>
  );

  const saveItemChanges = (item_no) => {
    SaveItemChanges(item_no, userChanges).then(
      () => {
        PushNotification("success", "Changes saved!", "The changes you made have been saved", true);
        fetchData();
        setUserChanges({});
      },
      () => PushNotification("error", "Unable to change", "Your changes was not saved..", true)
    );
  };

  const saveButton = (
    <Button
      type="primary"
      onClick={() => saveItemChanges(item_no)}
      icon={<SaveOutlined />}
      disabled={Object.keys(userChanges).length === 0}
    >
      Save changes
    </Button>
  );

  const currentTab = selectedTab.noTitleKey || TabList[0].key;

  return (
    <div style={{ margin: "2em" }}>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row justify="space-between">
          <Button icon={<ArrowLeftOutlined />} onClick={() => history.push("/productsearch")}>
            Back
          </Button>
          <Space size="middle">
            {isFeatureAvailable("productsearch_save_data") ? (
              <Tooltip placement="bottomRight" title={JSON.stringify(userChanges, null, "\t")}>
                {saveButton}
              </Tooltip>
            ) : (
              <div>{saveButton}</div>
            )}
            <Popover placement="bottomLeft" content={actionMenu} trigger="click">
              <Tooltip title="More actions">
                <Button icon={<MoreOutlined />} />
              </Tooltip>
            </Popover>
          </Space>
        </Row>
        <Card
          title={
            loadingData.items || loadingData.lookups ? (
              <Skeleton.Input style={{ width: 250 }} active />
            ) : (
              productData?.item.item_no + " - " + productData?.item.stylename
            )
          }
          loading={loadingData.items || loadingData.lookups}
          tabList={TabList}
          activeTabKey={currentTab}
          onTabChange={onTabChange}
        >
          <div style={{ padding: "1em 1.5em" }}>{tabContent(currentTab)}</div>
        </Card>
      </Space>
      <BackTop />
    </div>
  );
};

export default ProductCard;
