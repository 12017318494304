import { View } from "utils/Enums.util";

export const keyPressHandler = (
  event,
  view,
  setNavigationListItem,
  navigationListItem,
  styleFormatFlatStructure,
  allStylenames,
  expandedRowKeys,
  dataSku,
  dataSkuSorted,
  selectedRowKeys,
  selectedRowParentKeys,
  setExpandedRowKeys,
  handleSetSelectedRowKeysSingle
) => {
  if (document.activeElement.tagName === "INPUT") {
    return;
  }

  switch (event.keyCode) {
    case 38: //ArrowUp
      event.preventDefault();
      if (view === View.SKU) {
        navigationListItem.index === -1 && setNavigationListItem({ index: 0 });
        navigationListItem.index > 0 && setNavigationListItem({ index: navigationListItem.index - 1 });
      } else {
        navigationListItem.index === -1 && setNavigationListItem({ index: 0, record: styleFormatFlatStructure[0] });

        if (navigationListItem.index > 0) {
          const currentItem = navigationListItem.record;

          const indexOfCurrentItem = styleFormatFlatStructure.findIndex((el) => el.key === currentItem.key);
          const nextItem = styleFormatFlatStructure
            .slice(0, indexOfCurrentItem)
            .reverse()
            .find((el) => {
              if (allStylenames.includes(el.key)) {
                return true;
              } else {
                const parentItem = getParentOfStyleRow(el, styleFormatFlatStructure);
                return expandedRowKeys.includes(parentItem?.key);
              }
            });

          const newIndex = navigationListItem.index - 1;
          nextItem && setNavigationListItem({ record: nextItem, index: newIndex });
        }
      }
      break;
    case 40: //ArrowDown
      if (view === View.SKU) {
        navigationListItem.index === -1 && setNavigationListItem({ index: 0 });
        navigationListItem.index < dataSku.length && setNavigationListItem({ index: navigationListItem.index + 1 });
      } else {
        navigationListItem.index === -1 && setNavigationListItem({ index: 0, record: styleFormatFlatStructure[0] });

        if (navigationListItem.index < dataSku.length) {
          const currentItem = navigationListItem.record;

          const indexOfCurrentItem = styleFormatFlatStructure.findIndex((el) => el.key === currentItem.key);
          const nextItem = styleFormatFlatStructure
            .slice(indexOfCurrentItem + 1, styleFormatFlatStructure.length)
            .find((el) => {
              if (allStylenames.includes(el.key)) {
                return true;
              } else {
                const parentItem = getParentOfStyleRow(el, styleFormatFlatStructure);
                return expandedRowKeys.includes(parentItem?.key);
              }
            });

          const newIndex = navigationListItem.index + 1;
          nextItem && setNavigationListItem({ record: nextItem, index: newIndex });
        } else {
          setNavigationListItem({
            record: styleFormatFlatStructure[0],
            index: 0,
          });
        }
      }
      break;
    case 67: //C-key
      if (view === View.STYLE && navigationListItem.record) {
        let expandedRows = [...expandedRowKeys];
        const currentKeyHiglighted = navigationListItem.record.key;
        const isItemParent = (key) => !dataSkuSorted.find((d) => d.sku === key);

        const isParent = isItemParent(currentKeyHiglighted);

        if (expandedRows.includes(navigationListItem.record.key)) {
          if (isParent && expandedRows.includes(currentKeyHiglighted)) {
            if (currentKeyHiglighted.parentId) {
              expandedRows = expandedRows.filter((key) => key !== currentKeyHiglighted);
            } else {
              expandedRows = expandedRows.filter((key) => !key.includes(currentKeyHiglighted));
            }
            setExpandedRowKeys(expandedRows);
          }
        } else {
          if (isParent && !expandedRows.includes(currentKeyHiglighted)) {
            expandedRows.push(currentKeyHiglighted);
            setExpandedRowKeys(expandedRows);
          }
        }
      }
      break;
    case 88: //X-key
      const currentRowHiglighted = navigationListItem.record || dataSkuSorted[navigationListItem.index];
      if (currentRowHiglighted) {
        const ItemKey = currentRowHiglighted.key || currentRowHiglighted.sku;
        const isSelected = !selectedRowKeys.concat(selectedRowParentKeys).find((key) => key === ItemKey);
        handleSetSelectedRowKeysSingle(currentRowHiglighted, isSelected);
      }
      break;
    default:
      break;
  }
};

const getParentOfStyleRow = (element, styleFormatFlatStructure) => {
  if (styleFormatFlatStructure.includes(element.key)) {
    return;
  } else {
    if (element.parentId) {
      return styleFormatFlatStructure.find((el) => el.key === element.parentId);
    } else {
      const indexOfCurrentElement = styleFormatFlatStructure.findIndex((el) => el.key === element.key);
      return [...styleFormatFlatStructure].reverse().find((el) => {
        const indexOfEl = styleFormatFlatStructure.findIndex((e) => e.key === el.key);
        return indexOfEl < indexOfCurrentElement && !el.sku;
      });
    }
  }
};
