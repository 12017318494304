import { ReactNode, useState, useEffect } from "react";
import { Row, Table, Tooltip, Button } from "antd";

import { VerticalColumns, HorizontalColumns } from "./InfoComponentColumns";
import { getLocalStorageItem, setLocalStorageItem, getTablePageSize } from "utils/LocalStorage.util";
import { SettingOutlined } from "@ant-design/icons";
import ColumnsModal from "components/Modal/ColumnsModal.component";
import { sortByOrderInArray } from "utils/Sort.util";

const InfoComponent: ReactNode = ({ data, userChanges, setUserChanges, lookups }: any) => {
  const [showColumnModalVertical, setShowColumnModalVertical] = useState(false);
  const [showColumnModalHorizontal, setShowColumnModalHorizontal] = useState(false);
  const [hiddenColumnsListHorizontal, setHiddenColumnsListHorizontal] = useState(
    getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns")).ps_component_hori
      ? JSON.parse(getLocalStorageItem("hidden_columns")).ps_component_hori
      : []
  );
  const [hiddenColumnsListVertical, setHiddenColumnsListVertical] = useState(
    getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns")).ps_component_vert
      ? JSON.parse(getLocalStorageItem("hidden_columns")).ps_component_vert
      : []
  );

  useEffect(() => {
    const hidden_colums_hori = getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns"));
    !hidden_colums_hori.ps_component_hori &&
      setLocalStorageItem("hidden_columns", JSON.stringify({ ...hidden_colums_hori, ps_component_hori: [] }));

    const columns_order_hori = getLocalStorageItem("columns_order") && JSON.parse(getLocalStorageItem("columns_order"));
    !columns_order_hori.ps_component_hori &&
      setLocalStorageItem("columns_order", JSON.stringify({ ...columns_order_hori, ps_component_hori: [] }));

    const hidden_colums_vert = getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns"));
    !hidden_colums_vert.ps_component_vert &&
      setLocalStorageItem("hidden_columns", JSON.stringify({ ...hidden_colums_vert, ps_component_vert: [] }));

    const columns_order_vert = getLocalStorageItem("columns_order") && JSON.parse(getLocalStorageItem("columns_order"));
    !columns_order_vert.ps_component_vert &&
      setLocalStorageItem("columns_order", JSON.stringify({ ...columns_order_vert, ps_component_vert: [] }));
  }, []);

  const setChangesVert = (key: string, input: string, record: any) => {
    if (userChanges.item_component_vert) {
      const item_component_vert = [...userChanges.item_component_vert];

      let codeObjIndex = 0;
      const codeObject = item_component_vert.find((obj: any, idx: number) => {
        codeObjIndex = idx;
        return obj.code === record.code;
      });

      if (codeObject) {
        item_component_vert[codeObjIndex][key] = input;
        setUserChanges({ ...userChanges, item_component_vert });
      } else {
        item_component_vert.push({ code: record.code, [key]: input });
        setUserChanges({ ...userChanges, item_component_vert });
      }
    } else {
      setUserChanges({ ...userChanges, item_component_vert: [{ code: record.code, [key]: input }] });
    }
  };

  const setChangesHorz = (key: string, input: string, record: any) => {
    if (userChanges.item_component_horz) {
      const item_component_horz = [...userChanges.item_component_horz];

      let codeObjIndex = 0;
      const codeObject = item_component_horz.find((obj: any, idx: number) => {
        codeObjIndex = idx;
        return obj.code === record.code;
      });

      if (codeObject) {
        item_component_horz[codeObjIndex][key] = input;
        setUserChanges({ ...userChanges, item_component_horz });
      } else {
        item_component_horz.push({ code: record.code, [key]: input });
        setUserChanges({ ...userChanges, item_component_horz });
      }
    } else {
      setUserChanges({ ...userChanges, item_component_horz: [{ code: record.code, [key]: input }] });
    }
  };

  const onCancelColumnSelectionHorizontal = () => {
    setShowColumnModalHorizontal(false);
    setHiddenColumnsListHorizontal(JSON.parse(getLocalStorageItem("hidden_columns")).ps_component_hori);
  };
  const onCancelColumnSelectionVertical = () => {
    setShowColumnModalVertical(false);
    setHiddenColumnsListVertical(JSON.parse(getLocalStorageItem("hidden_columns")).ps_component_vert);
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <Row justify="end">
          <Tooltip title="Configure table columns">
            <Button shape="circle" icon={<SettingOutlined />} onClick={() => setShowColumnModalVertical(true)} />
          </Tooltip>
        </Row>
        <h3>Color code - Item vertical components</h3>
        <Table
          rowKey="code"
          scroll={{ x: 1000 }}
          pagination={{ position: ["bottomCenter"], defaultPageSize: getTablePageSize() }}
          columns={sortByOrderInArray(
            VerticalColumns(userChanges, setChangesVert, lookups),
            JSON.parse(getLocalStorageItem("columns_order")).ps_component_vert,
            "title"
          ).filter((column) => !hiddenColumnsListVertical.includes(column.title))}
          dataSource={data.item_component_vert}
          size="small"
        />
      </div>
      <div>
        <Row justify="end">
          <Tooltip title="Configure table columns">
            <Button shape="circle" icon={<SettingOutlined />} onClick={() => setShowColumnModalHorizontal(true)} />
          </Tooltip>
        </Row>
        <h3>Size code - Item horizontal components </h3>
        <Table
          rowKey="code"
          scroll={{ x: 1000 }}
          pagination={{ position: ["bottomCenter"], defaultPageSize: getTablePageSize() }}
          columns={sortByOrderInArray(
            HorizontalColumns(userChanges, setChangesHorz, lookups),
            JSON.parse(getLocalStorageItem("columns_order")).ps_component_hori,
            "title"
          ).filter((column) => !hiddenColumnsListHorizontal.includes(column.title))}
          dataSource={data.item_component_horz}
          size="small"
        />
      </div>
      {showColumnModalVertical && (
        <ColumnsModal
          visible
          onCancel={onCancelColumnSelectionVertical}
          localStorageId="ps_component_vert"
          columns={sortByOrderInArray(
            VerticalColumns(userChanges, setChangesVert, lookups),
            JSON.parse(getLocalStorageItem("columns_order")).ps_component_vert,
            "title"
          ).map((el) => el.title)}
          disabledSkuList={["Code"]}
        />
      )}
      {showColumnModalHorizontal && (
        <ColumnsModal
          visible
          onCancel={onCancelColumnSelectionHorizontal}
          localStorageId="ps_component_hori"
          columns={sortByOrderInArray(
            HorizontalColumns(userChanges, setChangesHorz, lookups),
            JSON.parse(getLocalStorageItem("columns_order")).ps_component_hori,
            "title"
          ).map((el) => el.title)}
          disabledSkuList={["Code"]}
        />
      )}
    </div>
  );
};

export default InfoComponent;
