import { Input, Select } from "antd";

const getFieldValue = (key: string, value: string, code: string, type: string, userChanges: any) => {
  if (userChanges[type]) {
    const changedValue = userChanges[type].find((obj: any) => obj.code === code);

    if (changedValue && changedValue[key]) {
      return changedValue[key];
    }
  }
  return value;
};

const searchSelectProps = {
  showSearch: true,
  filterOption: (input: any, option: any) =>
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
};

export const VerticalColumns = (userChanges: any, setChanges: any, lookups: any) => [
  {
    key: 1,
    title: "Code",
    dataIndex: "code",
    width: 200,
  },
  {
    key: 2,
    title: "Description",
    dataIndex: "description",
    width: 200,
    render: (text: string, record: any) => {
      return (
        <Input
          maxLength={60}
          onChange={(i) => setChanges("description", i.target.value, record)}
          value={getFieldValue("description", text, record.code, "item_component_vert", userChanges)}
        />
      );
    },
  },
  {
    key: 4,
    title: "Sortera",
    dataIndex: "sorting",
    width: 200,
  },
  {
    key: 5,
    title: "Variantkomponent",
    dataIndex: "variant_component",
    width: 200,
  },
  {
    key: 13,
    title: "Base color code",
    dataIndex: "base_color_code",
    width: 200,
    render: (text: string, record: any) => {
      return (
        <Select
          {...searchSelectProps}
          onChange={(i) => setChanges("base_color_code", i, record)}
          value={getFieldValue("base_color_code", text, record.code, "item_component_vert", userChanges)}
          options={lookups?.base_color_code}
          style={{ width: "100%" }}
        />
      );
    },
  },
];

export const HorizontalColumns = (userChanges: any, setChanges: any, lookups: any) => [
  {
    key: 1,
    title: "Code",
    dataIndex: "code",
    width: 200,
  },
  {
    key: 2,
    title: "Description",
    dataIndex: "description",
    width: 200,
    render: (text: string, record: any) => {
      return (
        <Input
          maxLength={60}
          onChange={(i) => setChanges("description", i.target.value, record)}
          value={getFieldValue("description", text, record.code, "item_component_horz", userChanges)}
        />
      );
    },
  },
  {
    key: 4,
    title: "Sortera",
    dataIndex: "sorting",
    width: 200,
  },
  {
    key: 5,
    title: "Variantkomponent",
    dataIndex: "variant_component",
    width: 200,
  },
  {
    key: 13,
    title: "Base size code",
    dataIndex: "base_size_code",
    width: 200,
    render: (text: string, record: any) => {
      return (
        <Select
          {...searchSelectProps}
          onChange={(i) => setChanges("base_size_code", i, record)}
          value={getFieldValue("base_size_code", text, record.code, "item_component_horz", userChanges)}
          options={lookups?.base_size_code}
          style={{ width: "100%" }}
          showSearch
        />
      );
    },
  },
];
