import { useState, useEffect, useMemo } from "react";
import { Layout } from "antd";
import PoStart from "./PoStart.page";
import PreparePo from "./PreparePo.page";
import { FetchProducts } from "services/Po.service";
import { getPreference } from "utils/LocalStorage.util";
import { filterArrayKeyword } from "utils/Filter.util";
import { fromSkuListToStyleList } from "utils/Format.util";
import { PushNotification } from "utils/pushNotification.util";
import { FetchLookupData } from "services/Pim.service";
import { getLocalStorageItem, setLocalStorageItem } from "utils/LocalStorage.util";

const { Content } = Layout;

const PurchaseOrder = ({ refreshNotifications }) => {
  const [originalData, setOriginalData] = useState([]);
  const [vendorDataSku, setVendorDataSku] = useState([]);
  const [vendorDataStyle, setVendorDataStyle] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState();
  const [vendor, setVendor] = useState();
  const [view, setView] = useState(getPreference("tableview") != null ? getPreference("tableview") : "style");
  const [globalFilter, setGlobalFilter] = useState();
  const [allStylenames, setAllStylenames] = useState([]);
  const [styleFormatFlatStructure, setStyleFormatFlatStructure] = useState([]);
  const [purchasingGroupList, setPurchasingGroupList] = useState([]);
  const [plants, setPlants] = useState([]);
  const [brands, setBrands] = useState([]);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    const hidden_colums = getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns"));
    !hidden_colums.po && setLocalStorageItem("hidden_columns", JSON.stringify({ ...hidden_colums, po: [] }));

    const columns_order = getLocalStorageItem("columns_order") && JSON.parse(getLocalStorageItem("columns_order"));
    !columns_order.po && setLocalStorageItem("columns_order", JSON.stringify({ ...columns_order, po: [] }));

    FetchLookupData("pim").then((res) => {
      const data = res?.data.lookups;
      setVendors(data.vendor);
      setBrands(data.brand);
      setPlants(data.plant);
      setPurchasingGroupList(data.purchasing_group);
    });
  }, []);

  useEffect(() => {
    if (originalData) {
      const dataFilteredOnVendor = vendor ? originalData.filter((el) => el.vendor_no === vendor) : originalData;
      const dataFilteredOnGlobalFilter = globalFilter
        ? filterArrayKeyword(dataFilteredOnVendor, globalFilter)
        : dataFilteredOnVendor;
      const dataInStyleFormat = fromSkuListToStyleList(dataFilteredOnGlobalFilter);
      setVendorDataSku(dataFilteredOnGlobalFilter);
      setVendorDataStyle(dataInStyleFormat);

      const flatStyleArray = [];
      const flatStylenameArray = [];

      dataInStyleFormat.forEach((stylename) => {
        flatStylenameArray.push(stylename.key);
        flatStyleArray.push(stylename);

        if (stylename.children) {
          stylename.children.forEach((firstChild) => {
            flatStyleArray.push(firstChild);

            if (firstChild.children) {
              firstChild.children.forEach((secondChild) => {
                flatStyleArray.push(secondChild);
              });
            }
          });
        }
      });

      setStyleFormatFlatStructure(flatStyleArray);
      setAllStylenames(flatStylenameArray);
    }
  }, [vendor, originalData, globalFilter]);

  const availableVendors = useMemo(() => {
    const vendorNrList = [];

    return originalData?.reduce((vendorList, skuItem) => {
      if (!vendorNrList.includes(skuItem.vendor_no)) {
        vendorNrList.push(skuItem.vendor_no);
        vendorList.push({
          value: skuItem.vendor_no,
          label: `${skuItem.vendor_no}${skuItem.NAME1_MIN ? "-" + skuItem.NAME1_MIN : skuItem.NAME1_MIN}`,
        });
      }
      return vendorList;
    }, []);
  }, [originalData]);

  const removeAllData = () => {
    setOriginalData([]);
    setVendor();
    setGlobalFilter();
  };

  const handlePrepareExecute = (formObject) => {
    setFetchingData(true);
    setSelectedFormData(formObject);
    formObject.vendor && setVendor(formObject.vendor);

    const ekgrp = formObject?.poGroup === "" ? undefined : formObject?.poGroup;
    const plant = formObject?.plant;
    const vendor_no = formObject?.vendor === "" ? undefined : formObject?.vendor;
    const mfrnr = formObject?.brand === "" ? undefined : formObject?.brand;
    const selections = formObject?.selections;

    FetchProducts({ ekgrp, plant, vendor_no, mfrnr, selections }).then(
      (res) => {
        const data = res?.data.results;
        setOriginalData(data);
        setFetchingData(false);
        if (!data.length) {
          PushNotification(
            "warning",
            "Response was empty",
            "The returned response was empty. Try again or contact support.",
            true
          );
          refreshNotifications();
        }
      },
      () => {
        setFetchingData(false);
        PushNotification(
          "error",
          "Cannot fetch data",
          "An error occurred while fetching data. See console for more details.",
          true
        );
        refreshNotifications();
      }
    );
  };

  return (
    <Content style={{ margin: "0 16px" }}>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        {originalData?.length ? (
          <PreparePo
            dataSku={vendorDataSku}
            dataStyle={vendorDataStyle}
            handleChangeVendor={setVendor}
            vendor={vendor}
            poGroup={selectedFormData?.poGroup}
            plant={selectedFormData?.plant}
            handleSetView={({ target }) => setView(target.value)}
            view={view}
            refreshNotifications={refreshNotifications}
            handleGlobalFilterChanged={setGlobalFilter}
            globalFilter={globalFilter}
            allStylenames={allStylenames}
            styleFormatFlatStructure={styleFormatFlatStructure}
            removeAllData={removeAllData}
            availableVendors={availableVendors}
            purchasingGroupList={purchasingGroupList}
          />
        ) : (
          <PoStart
            loading={fetchingData}
            onPrepareExecute={handlePrepareExecute}
            purchasingGroupList={purchasingGroupList}
            plants={plants}
            brands={brands}
            vendors={vendors}
          />
        )}
      </div>
    </Content>
  );
};

export default PurchaseOrder;
