import { useMemo } from "react";
import { Collapse, Row, Col, Card, Space, Select, Input, Form, InputNumber } from "antd";

const { Panel } = Collapse;
const { Option } = Select;

const PoCards = ({
  dataSku,
  modifiedDataList,
  selectedRowKeys,
  documentType,
  vendor,
  availableVendors,
  handleChangeVendor,
  poGroup,
  purchasingGroupList,
  setSelectedPoGroup,
  setDocumentType,
  orderRef,
  setOrderRef,
  sapCredentials,
  setSapCredentials,
  selectedGRProcTime,
  setSelectedGRProcTime,
}) => {
  const CalculateTotals = useMemo(() => {
    let grossAmount = 0;
    let netAmount = 0;
    let discount = 0;

    const selectedItemsReadyForPo = dataSku.filter((item) => {
      const mrpObject = modifiedDataList?.find((m) => m.key === item.sku);
      return mrpObject?.mrp > 0 && selectedRowKeys.some((key) => key === item.sku);
    });

    selectedItemsReadyForPo.forEach((item) => {
      const mrpObject = modifiedDataList?.find((m) => m.key === item.sku);

      if (documentType === "ZPRE") {
        const price = mrpObject.price ? mrpObject.price : item.preorder_price > 0 ? item.preorder_price : item.price;
        grossAmount += mrpObject.mrp * price;
        netAmount += mrpObject.mrp * price - (item.preorder_discount_pct * -1 * (mrpObject.mrp * price)) / 100;
      } else {
        const price = mrpObject.price || item.price;
        grossAmount += mrpObject.mrp * price;
        netAmount += mrpObject.mrp * price - (item.supplementary_discount_pct * -1 * (mrpObject.mrp * price)) / 100;
      }
    });

    return {
      gross: (Math.round((grossAmount + Number.EPSILON) * 100) / 100).toFixed(2),
      net: (Math.round((netAmount + Number.EPSILON) * 100) / 100).toFixed(2),
      discount: discount,
    };
  }, [dataSku, modifiedDataList, selectedRowKeys, documentType]);

  const sumMrp = useMemo(() => {
    if (!modifiedDataList.length) {
      return 0;
    }

    return modifiedDataList.reduce((totalMrp, line) => {
      if (!line.isParent && line.mrp) {
        return totalMrp + line.mrp;
      }
      return totalMrp;
    }, 0);
  }, [modifiedDataList]);

  return (
    <Collapse defaultActiveKey={["1"]} ghost>
      <Panel header="PO overview" key="1">
        <div className="site-card-border-less-wrapper">
          <Row gutter={16}>
            <Col span={8}>
              <Card bordered={false} style={{ height: 350 }}>
                <Space direction="vertical">
                  <div>
                    Vendor:
                    <br />
                    <Select
                      showSearch
                      value={vendor}
                      style={{ width: "100%" }}
                      options={availableVendors}
                      onChange={handleChangeVendor}
                      disabled={selectedRowKeys.length > 0}
                      placeholder="Select vendor"
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                    />
                  </div>
                  <div>
                    Purchasing Group:
                    <br />
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      placeholder="Purchasing Group"
                      value={poGroup}
                      options={purchasingGroupList}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(val) => setSelectedPoGroup(val)}
                    />
                  </div>
                  <div>
                    Document Type:
                    <br />
                    <Select
                      name="documentType"
                      onChange={(value) => setDocumentType(value)}
                      style={{ width: "100%" }}
                      placeholder="Document type"
                      showSearch
                      value={documentType}
                    >
                      <Option value="NB">NB</Option>
                      <Option value="ZPRE">ZPRE</Option>
                      <Option value="ZPAR">ZPAR</Option>
                    </Select>
                  </div>
                  <div>
                    GR proc time:
                    <br />
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={14}
                      value={selectedGRProcTime}
                      onChange={setSelectedGRProcTime}
                    />
                  </div>
                  <div>
                    Order Reference:
                    <br />
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Order Reference"
                      value={orderRef}
                      onChange={(e) => setOrderRef(e.target.value)}
                    />
                  </div>
                </Space>
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false} style={{ height: 350 }}>
                <Form layout={"vertical"}>
                  <Form.Item
                    label="SAP User"
                    name="sapuser"
                    colon={false}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="SAP User"
                      value={sapCredentials.user}
                      onChange={(e) => setSapCredentials({ ...sapCredentials, user: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="SAP Password"
                    name="sappassword"
                    colon={false}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="SAP Password"
                      value={sapCredentials.password}
                      onChange={(e) => setSapCredentials({ ...sapCredentials, password: e.target.value })}
                    />
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false} style={{ height: 350 }}>
                <p># line items: {dataSku.length}</p>
                <p># selected items: {selectedRowKeys.length}</p>
                <p>Sum mrp: {sumMrp}</p>
                <p>Gross Amount: {CalculateTotals.gross}</p>
                <p>Gross Discounts: {CalculateTotals.discount}</p>
                <p>Net Amount: {CalculateTotals.net}</p>
              </Card>
            </Col>
          </Row>
        </div>
      </Panel>
    </Collapse>
  );
};

export default PoCards;
