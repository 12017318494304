import { setLocalStorageItem, getLocalStorageItem } from "./LocalStorage.util";
import { notification } from "antd";
import { CloseCircleOutlined, InfoCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { uuidv4 } from "./Id.util";

export const PushNotification = (type: string, title: string, message: string, push: boolean = false): void => {
  // TODO: lagres i db også
  if (push) {
    const iconType = {
      success: <CheckCircleOutlined style={{ color: "#39db49" }} />,
      error: <CloseCircleOutlined style={{ color: "#db1818" }} />,
      warning: <ExclamationCircleOutlined style={{ color: "#db9418" }} />,
      info: <InfoCircleOutlined style={{ color: "#108ee9" }} />,
    }[type];

    notification.open({
      message: title,
      description: message,
      placement: "bottomRight",
      icon: iconType,
    });
  }

  let notifications = JSON.parse(getLocalStorageItem("notifications"));
  const id = uuidv4();

  notifications
    ? notifications.push({ type, title, message, date: new Date(), id })
    : (notifications = [{ type, title, message, date: new Date(), id }]);
  setLocalStorageItem("notifications", JSON.stringify(notifications));
};
