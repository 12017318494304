import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "7bddbc16-0cc5-4f27-ad01-4ae4ccbc1a75",
    authority: "https://login.microsoftonline.com/organizations",
    postLogoutRedirectUri: "https://dev.opp.outnordic.com/index.html",
  },
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);
