import { Radio } from "antd";
import { updatePreferenceObj, getPreference } from "utils/LocalStorage.util";
import { useState } from "react";

const DateFormat = () => {
  const [dateformat, setDateformat] = useState(getPreference("dateformat"));

  const onChange = (e) => {
    setDateformat(e.target.value);
    updatePreferenceObj("dateformat", e.target.value);
  };

  return (
    <>
      <h3>Date Format</h3>
      <Radio.Group
        onChange={onChange}
        value={dateformat}
        style={{ display: "grid", gridTemplateColumns: "100%", gap: "10px" }}
      >
        <Radio value="YYYY-MM-DD">2021-01-01</Radio>
        <Radio value="DD.MM.YYYY">01.01.2021</Radio>
      </Radio.Group>
    </>
  );
};

export default DateFormat;
