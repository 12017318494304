import "./Login.page.scss";

import { FC, useEffect } from "react";
import { Card, notification } from "antd";
import { GoogleLogin } from "react-google-login";
import { useHistory } from "react-router-dom";

import { setLocalStorageItem, getLocalStorageItem, deleteLocalStorageItem } from "utils/LocalStorage.util";
import { msalInstance } from "bootstrap/authProvider";
import { validateToken } from "services/Token.service";

import logo from "./ms-logo.webp";

const LoginPage: FC = () => {
  const history = useHistory();

  useEffect((): void => {
    getLocalStorageItem("token") && history.push("/");
  });

  const validateSignin = (): void => {
    validateToken().then(
      () => {
        history.push("/");
      },
      ({ response }) => {
        if (response?.status === 401) {
          deleteLocalStorageItem("token");
          history.push("/login");
        }
      }
    );
  };

  const handleGoogleLoginSucces = (response: any): void => {
    if (response?.tokenId) {
      setLocalStorageItem("token", response.tokenId);
      setLocalStorageItem("loginService", "google");
      validateSignin();
    }
  };

  const loginWithMs = async (): Promise<any> => {
    try {
      const loginResponse = await msalInstance.loginPopup({ prompt: "select_account", scopes: ["User.ReadWrite"] });
      setLocalStorageItem("token", loginResponse.accessToken);
      setLocalStorageItem("loginService", "microsoft");
      validateSignin();
    } catch (err) {
      notification["warning"]({
        message: "Unable to login",
        description: "We were unable to log you in with Microsoft. Make sure you have cookies enabled in the browser.",
      });
    }
  };

  const handleGoogleLoginFailure = (): void => {
    notification["warning"]({
      message: "Unable to login",
      description: "We were unable to log you in with Google. Make sure you have cookies enabled in the browser.",
    });
  };

  return (
    <div className="login-div">
      <div className="login-container">
        <img
          src="https://dm9fd9qvy1kqy.cloudfront.net/media/wysiwyg/2021/opp/on-fs-outnordic-logo.png"
          alt="Outnordic Logo"
          className="outnordic-logo"
        />
        <div className="login-box">
          <Card title="Sign in" style={{ width: 360, textAlign: "center" }}>
            <GoogleLogin
              clientId={"344824186855-agjpsvj6lr2pjhc88esp75vrd8bo9mj7.apps.googleusercontent.com"}
              buttonText="Sign in with Google"
              onSuccess={handleGoogleLoginSucces}
              onFailure={handleGoogleLoginFailure}
              cookiePolicy={"single_host_origin"}
              responseType="code,token"
              className="sign-in-with-google"
            />
            <br />
            <br />
            <div className="sign-in-with-ms" onClick={() => loginWithMs()}>
              <img src={logo} alt="Microsoft logo" style={{ height: "80%", marginRight: "10px" }}></img>Sign in with Microsoft
            </div>
            <br />
            <span style={{ fontSize: "0.8em" }}>
              Note: This application requires third party cookies in order to work properly
            </span>
          </Card>
        </div>
        <p className="copyright-text">©{new Date().getFullYear()} Outnordic Invest AB</p>
      </div>
    </div>
  );
};

export default LoginPage;
