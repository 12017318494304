import moment from "moment";

export const dateInThePast = (current: moment.Moment): boolean => {
  return current && current.isBefore(moment(), "day");
};

export const calculatePutawayDate = (date: string, gr_pr_time: number, format: string): string => {
  const chosenDeliveryDate = moment(date);
  const newDate = chosenDeliveryDate.add(gr_pr_time, "days");

  const dayOfWeek = newDate.isoWeekday();
  if (dayOfWeek === 6 || dayOfWeek === 7) {
    const nextMonday = newDate.add(1, "week").isoWeekday(1);
    return nextMonday.format(format);
  }

  return newDate.format(format);
};
