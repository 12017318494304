import React from "react";
import { Modal, List } from "antd";

const ConfirmPOModal = ({ showPoConfirmationModal, handleCreatePo, modifiedDataList, selectedRowKeys, handleCloseModal }) => {
  return (
    <Modal
      centered
      title="Confirm PO"
      visible={showPoConfirmationModal}
      okText={"Create PO"}
      onOk={handleCreatePo}
      onCancel={handleCloseModal}
    >
      <p>
        Some items has either a mrp value or delivery date, but are not a part of your selection. Confirm that these should
        not be included before you continue.
      </p>
      <List
        dataSource={modifiedDataList.filter(
          (obj) => !selectedRowKeys.includes(obj.key) && !obj.isParent && (obj.mrp > 0 || obj.deliveryDate)
        )}
        renderItem={(item) => <List.Item>{item?.key}</List.Item>}
      />
    </Modal>
  );
};

export default ConfirmPOModal;
