import React, { useState } from "react";
import { Input, Button, Timeline, Tooltip, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import jwt_decode from "jwt-decode";

import { getLocalStorageItem, getPreference } from "../utils/LocalStorage.util";
import moment from "moment";
import { uuidv4 } from "../utils/Id.util";

var token = getLocalStorageItem("token");
var decoded = token && jwt_decode(token);

const ProductComment = () => {
  const [commentInput, setCommentInput] = useState();
  const [comments, setComments] = useState([
    { key: 1, text: "Comment 1", created: "2021-01-01", user: "Joar", uid: "jc@fjellsport.no" },
    { key: 2, text: "Comment 2", created: "2021-01-01", user: "Mikkel", uid: "mikkel@fjellsport.no" },
    { key: 3, text: "Comment 3", created: "2021-01-01", user: "Jørgen", uid: "je@fjellsport.no" },
  ]);
  const handleDelete = (key) => {
    const deleteComment = comments.filter((comment) => comment.key !== key);
    setComments(deleteComment);
  };

  const addNewComment = () => {
    setComments([
      ...comments,
      {
        key: uuidv4(),
        text: commentInput,
        created: new Date(),
        user: decoded?.given_name,
        uid: decoded?.email,
      },
    ]);
    setCommentInput("");
  };
  return (
    <>
      <Timeline style={{ margin: "1em" }}>
        {comments &&
          comments.map((comment) => {
            return (
              <Timeline.Item key={comment.key}>
                {moment(comment.created).fromNow(getPreference("dateformat"))} ago - by {comment.user}
                {decoded?.email === comment.uid && (
                  <Popconfirm
                    title="Are you sure you want to delete this comment?"
                    type="link"
                    onConfirm={() => handleDelete(comment.key)}
                  >
                    <Tooltip title="Delete comment">
                      <DeleteOutlined
                        style={{ color: "#1890ff", fontSize: "1.1em", position: "relative", top: "0px", padding: "5px" }}
                      />
                    </Tooltip>
                  </Popconfirm>
                )}
                <br /> {comment.text}
              </Timeline.Item>
            );
          })}
      </Timeline>
      <Input.TextArea
        onChange={(input) => setCommentInput(input.target.value)}
        rows={4}
        placeholder="Add your comment here..."
        value={commentInput}
      />
      <Button onClick={() => addNewComment()} style={{ margin: "1em 0em" }}>
        Add comment
      </Button>
    </>
  );
};

export default ProductComment;
