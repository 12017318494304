import HttpRequest from "../bootstrap/axios";
import { SelectedItem, UploadImport, FetchDataForm } from "../interfaces/PO.interface";

export const FetchProducts = (fetchData: FetchDataForm) => {
  return HttpRequest.get("/tmp/api/po/data-for-prepare-po-2", {
    params: {
      ekgrp: fetchData.ekgrp,
      plant: fetchData.plant,
      vendor_no: fetchData.vendor_no,
      mfrnr: fetchData.mfrnr,
      selections: fetchData.selections,
    },
  });
};

export const createPo = (
  vendor: string,
  poGroup: string,
  plant: string,
  selectedItemsReadyForPo: SelectedItem[],
  sapUsername: string,
  sapPassword: string,
  doctype: string,
  orderRef: string,
  dateformat: string
) =>
  HttpRequest.post("/tmp/api/po/create", {
    vendornr: vendor,
    ekgrp: poGroup,
    plant,
    user: sapUsername,
    password: sapPassword,
    doctype,
    dateformat,
    ref_1: orderRef,
    "line-items": selectedItemsReadyForPo,
  });

export const uploadPo = (form: UploadImport) => HttpRequest.post("/tmp/api/po/import", form);
