export const columns = (changedData, setChangedData) => [
  {
    key: "sku",
    dataIndex: "sku",
    title: "Sku",
  },
  {
    key: "currency_code",
    dataIndex: "currency_code",
    title: "currency_code",
  },
  {
    key: "created_at",
    dataIndex: "created_at",
    title: "created_at",
  },
  {
    key: "lead_days",
    dataIndex: "lead_days",
    title: "lead_days",
  },
  {
    key: "minbm",
    dataIndex: "minbm",
    title: "minbm",
  },
  {
    key: "preorder_discount_pct",
    dataIndex: "preorder_discount_pct",
    title: "preorder_discount_pct",
  },
  {
    key: "preorder_price",
    dataIndex: "preorder_price",
    title: "preorder_price",
  },
  {
    key: "pricat_no",
    dataIndex: "pricat_no",
    title: "pricat_no",
  },
  {
    key: "pricat_season",
    dataIndex: "pricat_season",
    title: "pricat_season",
  },
  {
    key: "price",
    dataIndex: "price",
    title: "price",
  },
  {
    key: "purchasing_org",
    dataIndex: "purchasing_org",
    title: "purchasing_org",
  },
  {
    key: "season_code",
    dataIndex: "season_code",
    title: "season_code",
  },
  {
    key: "supplementary_discount_pct",
    dataIndex: "supplementary_discount_pct",
    title: "supplementary_discount_pct",
  },
  {
    key: "tax_code",
    dataIndex: "tax_code",
    title: "tax_code",
  },
  {
    key: "updated_at",
    dataIndex: "updated_at",
    title: "updated_at",
  },
  {
    key: "vendor_no",
    dataIndex: "vendor_no",
    title: "vendor_no",
  },
  {
    key: "vendor_sku",
    dataIndex: "vendor_sku",
    title: "vendor_sku",
  },
];
