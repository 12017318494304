import React from "react";
import { Modal, Button, Result, notification } from "antd";

const SuccessModal = ({ orderNumber, handleCloseModal }) => {
  return (
    <Modal
      centered
      visible={orderNumber}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      footer={[
        <Button
          key="back"
          onClick={() => {
            navigator.clipboard.writeText(orderNumber);
            notification["success"]({
              message: "Copied!",
              description: "PO number was copied!",
              placement: "bottomRight",
            });
          }}
        >
          Copy PO number
        </Button>,
        <Button key="submit" type="primary" onClick={handleCloseModal}>
          Ok
        </Button>,
      ]}
    >
      <Result status="success" title="Successfully Created Purchase Order" subTitle={`Po number: ${orderNumber}`} />
    </Modal>
  );
};

export default SuccessModal;
