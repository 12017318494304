import React from "react";
import BaseTable, { Column } from "react-base-table";
import { Checkbox } from "antd";

const SelectionCell = (props) => {
  const { rowData, column } = props;
  const { onChange, selectedRowKeys, rowKey } = column;
  const checked = selectedRowKeys?.includes(rowData[rowKey]);

  const handleChange = (e) => onChange({ selected: e.target.checked, rowData });

  return <Checkbox checked={checked} onChange={handleChange} />;
};

export default class SelectionBaseTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedRowKeys && nextProps.selectedRowKeys.length !== prevState.selectedRowKeys.length) {
      return {
        selectedRowKeys: nextProps.selectedRowKeys,
      };
    }
    return null;
  }

  _handleSelectChange = ({ selected, rowData }) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    const key = rowData[this.props.rowKey];

    if (selected) {
      if (!selectedRowKeys.includes(key)) selectedRowKeys.push(key);
    } else {
      const index = selectedRowKeys.indexOf(key);
      if (index > -1) {
        selectedRowKeys.splice(index, 1);
      }
    }
    this.props.onSelectionChange(rowData, selected);
    this.setState({ selectedRowKeys });
  };

  setRef = (ref) => (this.table = ref);

  componentDidUpdate(prevProps) {
    if (prevProps.navigationListIndex && this.props.navigationListIndex < prevProps.navigationListIndex) {
      this.table.scrollToRow(this.props.navigationListIndex - 1, "auto");
    } else if (prevProps.navigationListIndex && this.props.navigationListIndex > prevProps.navigationListIndex) {
      this.table.scrollToRow(this.props.navigationListIndex + 1, "auto");
    }
  }

  render() {
    const { columns, children, selectable, selectionColumnProps, showSelectionColumn, ...rest } = this.props;
    const { selectedRowKeys } = this.state;

    // you'd better memoize this operation
    let _columns = columns;
    const selectionColumn = {
      width: 50,
      flexShrink: 0,
      resizable: false,
      sortable: true,
      cellRenderer: SelectionCell,
      ...selectionColumnProps,
      key: "selection",
      rowKey: this.props.rowKey,
      selectedRowKeys,
      frozen: Column.FrozenDirection.LEFT,
      onChange: this._handleSelectChange,
    };

    if (showSelectionColumn) {
      _columns = [selectionColumn, ..._columns];
    }
    return <BaseTable {...rest} columns={_columns} ref={this.setRef} />;
  }
}

SelectionBaseTable.defaultProps = {
  ...BaseTable.defaultProps,
};
