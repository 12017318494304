import { notification } from "antd";

import { isFeatureAvailable } from "../components/Feature/Feature.component";
import { FetchVersion } from "../services/Version.service";

export const VersionCheck = (): any => {
  var notified = false;
  var version = "";

  if (!isFeatureAvailable("version_checking_disabled")) {
    FetchVersion().then(
      (response) => (version = response?.data?.version),
      () => console.warn("Cannot fetch version from /version.json")
    );

    const versionCheckInterval = setInterval(() => {
      FetchVersion().then(
        (response) => {
          if (version !== response?.data?.version && notified === false) {
            notification.info({
              message: "A new version is available",
              description: "Please reload the browser to update",
              placement: "bottomRight",
              duration: 0,
            });

            notified = true;
            clearInterval(versionCheckInterval);
          }
        },
        () => console.warn("Cannot fetch version from /version.json")
      );
    }, 600000);
  }
};
