import { useState, useEffect, useMemo } from "react";
import { Table, Button, Row, Tooltip } from "antd";

import { columns } from "./PurchasingInfoRecordColumns";
import ContextMenu, { openContextMenu } from "components/ContextMenu/ContextMenu.component";
import { getTablePageSize, getLocalStorageItem, setLocalStorageItem } from "utils/LocalStorage.util";
import ColumnsModal from "components/Modal/ColumnsModal.component";
import { sortByOrderInArray } from "utils/Sort.util";
import { SettingOutlined } from "@ant-design/icons";

const PurchasingInfoRecord = ({ data }) => {
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [contextMenu, setContextMenu] = useState({
    selectedRows,
    visible: false,
    x: 0,
    y: 0,
  });
  const [showColumnModal, setShowColumnModal] = useState(false);
  const [hiddenColumnsList, setHiddenColumnsList] = useState(
    getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns")).ps_purchasing_info_record
      ? JSON.parse(getLocalStorageItem("hidden_columns")).ps_purchasing_info_record
      : []
  );

  useEffect(() => {
    const hidden_colums = getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns"));
    !hidden_colums.ps_purchasing_info_record &&
      setLocalStorageItem("hidden_columns", JSON.stringify({ ...hidden_colums, ps_purchasing_info_record: [] }));

    const columns_order = getLocalStorageItem("columns_order") && JSON.parse(getLocalStorageItem("columns_order"));
    !columns_order.ps_purchasing_info_record &&
      setLocalStorageItem("columns_order", JSON.stringify({ ...columns_order, ps_purchasing_info_record: [] }));
  }, []);

  const rowSelection = {
    selectedRows,
    onChange: (keys, rows) => setSelectedRows({ keys, rows }),
  };

  const sortedObject = useMemo(() => {
    return data.reduce((sortedObject, sku) => {
      if (sortedObject[sku.vendor_no]) {
        if (sortedObject[sku.vendor_no][sku.plant]) {
          sortedObject[sku.vendor_no][sku.plant] = [...sortedObject[sku.vendor_no][sku.plant], sku];
        } else {
          sortedObject[sku.vendor_no][sku.plant] = [sku];
        }
      } else {
        sortedObject[sku.vendor_no] = {};
        sortedObject[sku.vendor_no][sku.plant] = [sku];
      }
      return sortedObject;
    }, {});
  }, [data]);

  const onCancelColumnSelection = () => {
    setShowColumnModal(false);
    setHiddenColumnsList(JSON.parse(getLocalStorageItem("hidden_columns")).ps_purchasing_info_record);
  };

  const vendors = useMemo(() => {
    return Object.keys(sortedObject);
  }, [sortedObject]);

  const vendorSection = (vendor) => {
    const vendorPlants = Object.keys(sortedObject[vendor]);

    return (
      <div key={vendor}>
        <h2 style={{ borderBottom: "1px solid #dedede", paddingBottom: "0.5em" }}>Vendor {vendor}</h2>
        {vendorPlants.map((plant) => {
          return (
            <div key={plant}>
              <h3 style={{ margin: "1.3em 0" }}>Plant {plant}</h3>

              <Table
                rowKey="sku"
                dataSource={sortedObject[vendor][plant]}
                columns={sortByOrderInArray(
                  columns(),
                  JSON.parse(getLocalStorageItem("columns_order")).ps_purchasing_info_record,
                  "title"
                ).filter((column) => !hiddenColumnsList.includes(column.title))}
                rowSelection={rowSelection}
                pagination={{ position: ["bottomCenter"], defaultPageSize: getTablePageSize() }}
                onRow={(record) => {
                  return {
                    onContextMenu: (event) => openContextMenu(record, contextMenu, setContextMenu, selectedRows, event),
                  };
                }}
                scroll={{ x: 2000 }}
                size="small"
                bordered
              ></Table>
              <ContextMenu {...contextMenu} />
              {showColumnModal && (
                <ColumnsModal
                  visible
                  onCancel={onCancelColumnSelection}
                  localStorageId="ps_purchasing_info_record"
                  columns={sortByOrderInArray(
                    columns(),
                    JSON.parse(getLocalStorageItem("columns_order")).ps_purchasing_info_record,
                    "title"
                  ).map((el) => el.title)}
                  disabledSkuList={["Sku"]}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  if (vendors.length > 0) {
    return (
      <>
        <Row justify="end" style={{ marginBottom: "2em" }}>
          <Tooltip title="Configure table columns">
            <Button shape="circle" icon={<SettingOutlined />} onClick={() => setShowColumnModal(true)} />
          </Tooltip>
        </Row>
        {vendors.map((vendor) => vendorSection(vendor))}
      </>
    );
  } else {
    return <p>No purchasing info record data was found for this item</p>;
  }
};

export default PurchasingInfoRecord;
