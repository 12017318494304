import React, { useState } from "react";
import { Menu, Badge, Drawer, Space, Card, Divider } from "antd";
import { NotificationOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { msalInstance } from "../bootstrap/authProvider";

import { deleteLocalStorageItem, getLocalStorageItem } from "../utils/LocalStorage.util";
import DateFormat from "./Preferences/DateFormat.component";
import TableView from "./Preferences/TableView.component";
import TablePageSize from "./Preferences/TablePageSize.component";
import NotificationModal from "./Modal/NotificationModal.component";

const { SubMenu } = Menu;

const logoImage = "https://dm9fd9qvy1kqy.cloudfront.net/media/wysiwyg/2021/opp/on-fs-outnordic-logo.png";

const Navigation = ({ notificationsModal, setNotificationsModal }) => {
  const [showDrawer, setShowDrawer] = useState();

  const history = useHistory();

  const showNotifications = () => {
    setNotificationsModal({ ...notificationsModal, visible: true });
  };

  const LogOut = () => {
    let logOutObj = null;
    if (msalInstance.getAllAccounts().length === 1) {
      logOutObj = { account: msalInstance.getAllAccounts()[0].username };
    }
    getLocalStorageItem("loginService") === "microsoft" && msalInstance.logout(logOutObj);
    deleteLocalStorageItem("token");
    history.push("/login");
  };

  return (
    <>
      <Menu theme="dark" mode="horizontal" selectable={false}>
        <Menu.Item key="4">
          <Link to="/">
            <img src={logoImage} alt="logo" height="14px" />
          </Link>
        </Menu.Item>
        <SubMenu key="user" style={{ float: "right" }} icon={<UserOutlined />}>
          <Menu.Item key="user:1" onClick={() => LogOut()}>
            Logout
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="3"
          style={{ float: "right" }}
          onClick={() => setShowDrawer(true)}
          icon={<SettingOutlined />}
        ></Menu.Item>
        <Menu.Item
          key="1"
          onClick={showNotifications}
          icon={
            notificationsModal?.notifications ? (
              <Badge count={notificationsModal?.notifications.length} size="small" style={{ borderColor: "#ff4d4f" }}>
                <NotificationOutlined style={{ color: "rgba(255, 255, 255, 0.65)" }} />
              </Badge>
            ) : (
              <NotificationOutlined />
            )
          }
          style={{ float: "right" }}
        ></Menu.Item>
      </Menu>
      <Drawer placement="right" closable={false} onClose={() => setShowDrawer(false)} visible={showDrawer}>
        <Space direction="vertical">
          <Divider>General</Divider>
          <Card>
            <DateFormat />
          </Card>
          <Card>
            <TablePageSize />
          </Card>
          <Divider>PO</Divider>
          <Card>
            <TableView />
          </Card>
        </Space>
      </Drawer>
      <NotificationModal notificationsModal={notificationsModal} setNotificationsModal={setNotificationsModal} />
    </>
  );
};

export default Navigation;
