import "./ContextMenu.component.scss";

import { InteractionOutlined } from "@ant-design/icons";
import { SyncItem } from "services/Pim.service";
import { PushNotification } from "utils/pushNotification.util";

export const openContextMenu = (record, contextMenu, setContextMenu, selectedRows, event, posFix = true) => {
  event?.preventDefault();
  if (!contextMenu.visible) {
    document.addEventListener(`click`, function onClickOutside() {
      setContextMenu({ visible: false });
      document.removeEventListener(`click`, onClickOutside);
    });
  }

  setContextMenu({
    selectedRows,
    record,
    visible: true,
    x: posFix ? event.pageX - 30 : event.pageX,
    y: posFix ? event.pageY - 130 : event.pageY,
  });
};

const actionSync = (id) => {
  SyncItem(id).then(
    () => PushNotification("success", "Synced item", `Item ${id} was synced!`, true),
    () => PushNotification("error", "Unable to sync", `Item ${id} could not be synced!`, true)
  );
};

const ContextMenu = ({ record, selectedRows, visible, x, y }) => {
  if (visible) {
    if (selectedRows && selectedRows.length > 1) {
      var affectingRows = selectedRows.length;
    }
    return (
      <ul className="popup" style={{ left: `${x}px`, top: `${y}px` }}>
        <li className="noselect" onClick={() => console.log(`Clicked ${record.item_no ? record.item_no : record.sku}`)}>
          {affectingRows ? "Affecting: " + affectingRows + " rows" : record.item_no ? record.item_no : record.sku}
        </li>
        <li
          onClick={() =>
            affectingRows ? selectedRows.forEach((item) => actionSync(item.item_no)) : actionSync(record.item_no)
          }
        >
          <InteractionOutlined /> {affectingRows ? "Sync rows" : "Sync row"}
        </li>
      </ul>
    );
  } else {
    return null;
  }
};

export default ContextMenu;
