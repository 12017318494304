import "./PurchaseOrder.scss";

import { Card, Form, Button, Upload, Input, Alert, Spin, Select, Tabs, Radio, Checkbox } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState } from "react";
import PropTypes from "prop-types";

import { PushNotification } from "utils/pushNotification.util";
import { uploadPo } from "services/Po.service";
import Feature from "components/Feature/Feature.component";
import { getPreference } from "utils/LocalStorage.util";

const { Dragger } = Upload;

const PoStart = ({ onPrepareExecute, loading, vendors, brands, plants, purchasingGroupList }) => {
  const [createPoFormData, setCreatePoFormData] = useState({ plant: "5000", poType: "single", selections: "full" });
  const [status, setStatus] = useState("");
  const [importType, setImportType] = useState("single");
  const [fileUpload, setFileUpload] = useState();

  const { TabPane } = Tabs;

  const createPoForm = () => {
    onPrepareExecute(createPoFormData);
  };

  const fileUploadProps = {
    accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    progress: { strokeWidth: 2, showInfo: true },
    maxCount: 1,
    beforeUpload: (file) => {
      setFileUpload(file);
      return false;
    },
    onRemove: () => {
      setFileUpload();
    },
  };

  const objectToFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const submitImport = (submitObject) => {
    setStatus(
      <Spin tip="Loading...">
        <Alert message="Creating PO..." description="Waiting for task to complete" type="info" />
      </Spin>
    );
    // assign uploaded file to object
    submitObject.file = fileUpload;
    submitObject.dateformat = getPreference("dateformat");

    uploadPo(objectToFormData(submitObject)).then(
      (response) => {
        setStatus(
          <Alert
            message="PO Created"
            description={"Reference ID: " + JSON.stringify(response?.data?.result)}
            type="success"
          />
        );
        PushNotification("success", "Created PO", "Created PO with id: " + response?.data?.result[1], false);
        setFileUpload();
      },
      (error) => {
        const errors = (
          <div>
            <h3>Errors:</h3>
            <table style={{ width: "100%" }}>
              <tr>
                <th>Parameter</th>
                <th>Row</th>
                <th>Sku</th>
                <th>Gtin</th>
                <th>Message</th>
                <th>Number</th>
                <th>Id</th>
                <th>System</th>
              </tr>
              {error?.response?.data.result[1]
                .filter((error) => error.TYPE === "E")
                .map((error) => (
                  <tr>
                    <td>{error.PARAMETER}</td>
                    <td>{error.ROW}</td>
                    <td>{error.SKU}</td>
                    <td>{error.GTIN14}</td>
                    <td>{error.MESSAGE}</td>
                    <td>{error.NUMBER}</td>
                    <td>{error.ID}</td>
                    <td>{error.SYSTEM}</td>
                  </tr>
                ))}
            </table>
            <br />
            <h3>Other potential problems:</h3>
            <table style={{ width: "100%" }}>
              <tr>
                <th>Parameter</th>
                <th>Row</th>
                <th>Sku</th>
                <th>Gtin</th>
                <th>Message</th>
                <th>Number</th>
                <th>Id</th>
                <th>System</th>
              </tr>
              {error?.response?.data.result[1]
                .filter((error) => error.TYPE !== "E")
                .map((error) => (
                  <tr>
                    <td>{error.PARAMETER}</td>
                    <td>{error.ROW}</td>
                    <td>{error.SKU}</td>
                    <td>{error.GTIN14}</td>
                    <td>{error.MESSAGE}</td>
                    <td>{error.NUMBER}</td>
                    <td>{error.ID}</td>
                    <td>{error.SYSTEM}</td>
                  </tr>
                ))}
            </table>
          </div>
        );

        setStatus(<Alert message="Could not create PO" description={errors} type="error" />);
      }
    );
  };

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Create Purchase Order" key="1">
        <Card title="Create Purchase Order">
          <Form onFinish={createPoForm} layout="vertical" initialValues={createPoFormData}>
            <Form.Item name="vendor" label="Vendor" tooltip="Start typing the vendor name to see suggestions">
              <Select
                showSearch
                options={vendors}
                onChange={(vendor) => setCreatePoFormData({ ...createPoFormData, vendor })}
                placeholder="Vendors"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              />
            </Form.Item>
            <Form.Item name="brand" label="Brand" tooltip="Start typing the brand name to see suggestions">
              <Select
                showSearch
                options={brands}
                onChange={(brand) => setCreatePoFormData({ ...createPoFormData, brand })}
                placeholder="Brands"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              />
            </Form.Item>
            <Form.Item name="plant" label="Plant" tooltip="Start typing the plant name to see suggestions">
              <Select
                showSearch
                options={plants}
                placeholder="Plant"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(plant) => setCreatePoFormData({ ...createPoFormData, plant })}
              />
            </Form.Item>
            <Form.Item name="poGroup" label="Purchasing Group">
              <Select
                showSearch
                options={purchasingGroupList}
                onChange={(poGroup) => setCreatePoFormData({ ...createPoFormData, poGroup })}
                placeholder="Purchasing Group"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              />
            </Form.Item>
            <Feature name="po-execute-selections">
              <Form.Item name="selections" label="Selections">
                <Radio.Group
                  onChange={(event) => setCreatePoFormData({ ...createPoFormData, selections: event.target.value })}
                >
                  <Radio value="full">Full</Radio>
                  <Radio value="optimized">Optimized</Radio>
                </Radio.Group>
              </Form.Item>
            </Feature>
            <Form.Item name="submit">
              <Button type="primary" htmlType="submit" loading={loading}>
                Execute
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </TabPane>
      <TabPane tab="Import Purchase Order" key="2">
        <Card title="Import Purchase Order">
          <Form layout="vertical" onFinish={submitImport}>
            <Form.Item name="poType" rules={[{ required: true }]}>
              <Radio.Group
                options={[
                  { label: "Single PO", value: "single" },
                  { label: "Multi PO", value: "multi" },
                ]}
                onChange={({ target }) => setImportType(target.value)}
                value={importType}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>

            <Form.Item name="documentType" label="Document type" rules={[{ required: true }]}>
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={[
                  { label: "ZPRE", value: "ZPRE" },
                  { label: "NB", value: "NB" },
                  { label: "ZPAR", value: "ZPAR" },
                ]}
              />
            </Form.Item>
            <Form.Item name="sapUser" label="SAP User" rules={[{ required: true }]}>
              <Input placeholder="User" />
            </Form.Item>
            <Form.Item name="sapPassword" label="SAP Password" rules={[{ required: true }]}>
              <Input type="password" placeholder="Password" />
            </Form.Item>
            {importType === "single" && (
              <>
                <Form.Item name="ekgrp" label="SAP Purchasing Group (ekgrp)" rules={[{ required: true }]}>
                  <Input placeholder="SAP Purchasing Group (ekgrp)" />
                </Form.Item>
                <Form.Item name="sapVendor" label="SAP Vendor nr." rules={[{ required: true }]}>
                  <Input placeholder="SAP Vendor nr." />
                </Form.Item>
                <Form.Item name="poReference" label="Vendor PO Reference">
                  <Input placeholder="Vendor PO Reference" />
                </Form.Item>
              </>
            )}
            <Form.Item name="comment" label="Comment">
              <Input placeholder="Comment" />
            </Form.Item>
            {/* 
            <p>Spreadsheet or Excel</p>
            <Form.Item name="url">
              <Input placeholder="https://" size="medium" style={{ marginBottom: "2em" }} />
              <p>------------ or ------------</p>
            </Form.Item> */}
            <Form.Item name="file">
              <Dragger {...fileUploadProps} style={{ maxWidth: "400px" }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Dragger>
            </Form.Item>

            <Form.Item name="ignoreZero" valuePropName="checked">
              <Checkbox>Ignore zero quantities</Checkbox>
            </Form.Item>

            <Form.Item name="sync" valuePropName="checked">
              <Checkbox>Sync Items</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Import PO
              </Button>
            </Form.Item>

            {status}
          </Form>
        </Card>
      </TabPane>
    </Tabs>
  );
};

PoStart.propTypes = {
  onPrepareExecute: PropTypes.func,
  loading: PropTypes.bool,
};

export default PoStart;
