import { Form, Switch, Input, Select, Table, Row, Tooltip, Button } from "antd";
import { useState, useEffect } from "react";
import { getLocalStorageItem, setLocalStorageItem, getTablePageSize } from "utils/LocalStorage.util";

import ContextMenu, { openContextMenu } from "../ContextMenu/ContextMenu.component";
import { columns } from "./PurchasingColumns";
import { SettingOutlined } from "@ant-design/icons";
import ColumnsModal from "../Modal/ColumnsModal.component";
import { sortByOrderInArray } from "utils/Sort.util";

const Purchasing = ({ data, lookups }) => {
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [contextMenu, setContextMenu] = useState({
    selectedRows,
    visible: false,
    x: 0,
    y: 0,
  });

  const [showColumnModal, setShowColumnModal] = useState(false);
  const [hiddenColumnsList, setHiddenColumnsList] = useState(
    getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns")).ps_purchasing
      ? JSON.parse(getLocalStorageItem("hidden_columns")).ps_purchasing
      : []
  );

  useEffect(() => {
    const hidden_colums = getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns"));
    !hidden_colums.ps_purchasing &&
      setLocalStorageItem("hidden_columns", JSON.stringify({ ...hidden_colums, ps_purchasing: [] }));

    const columns_order = getLocalStorageItem("columns_order") && JSON.parse(getLocalStorageItem("columns_order"));
    !columns_order.ps_purchasing &&
      setLocalStorageItem("columns_order", JSON.stringify({ ...columns_order, ps_purchasing: [] }));
  }, []);

  const rowSelection = {
    selectedRows,
    onChange: (keys, rows) => setSelectedRows({ keys, rows }),
  };

  const onCancelColumnSelection = () => {
    setShowColumnModal(false);
    setHiddenColumnsList(JSON.parse(getLocalStorageItem("hidden_columns")).ps_purchasing);
  };

  if (data.item_purchasing?.length > 0) {
    return data.item_purchasing.map((org) => {
      const skus = data.item_sku_purchasing.filter((sku) => sku.purchasing_org === org.purchasing_org);
      return (
        <div key={org.purchasing_org}>
          <Form initialValues={org} layout="vertical">
            <h2>Organization {org.purchasing_org}</h2>

            <div style={{ width: "calc(100% - 4em)", display: "grid", gridTemplateColumns: "50% 50%", gap: "4em" }}>
              <div>
                <Form.Item label="Activated" name="active" valuePropName="checked">
                  <Switch disabled />
                </Form.Item>
                <Form.Item label="Product Id" name="product_id">
                  <Input disabled />
                </Form.Item>
                <Form.Item label="Item status" name="pfsitem_status">
                  <Input disabled />
                </Form.Item>
              </div>
              <div>
                <Form.Item label="Season" name="pfsseason">
                  <Select options={lookups?.season} disabled />
                </Form.Item>
                <Form.Item label="Created at" name="created_at">
                  <Input disabled />
                </Form.Item>
                <Form.Item label="Updated at" name="updatet_at">
                  <Input disabled />
                </Form.Item>
              </div>
            </div>
          </Form>
          <Row justify="end" style={{ marginBottom: "2em" }}>
            <Tooltip title="Configure table columns">
              <Button shape="circle" icon={<SettingOutlined />} onClick={() => setShowColumnModal(true)} />
            </Tooltip>
          </Row>
          <Table
            rowKey="sku"
            dataSource={skus}
            rowSelection={rowSelection}
            columns={sortByOrderInArray(
              columns(),
              JSON.parse(getLocalStorageItem("columns_order")).ps_purchasing,
              "title"
            ).filter((column) => !hiddenColumnsList.includes(column.title))}
            pagination={{ position: ["bottomCenter"], defaultPageSize: getTablePageSize() }}
            onRow={(record) => {
              return {
                onContextMenu: (event) => openContextMenu(record, contextMenu, setContextMenu, selectedRows, event),
              };
            }}
            scroll={{ x: 1500 }}
            size="small"
            bordered
          />
          <ContextMenu {...contextMenu} />
          {showColumnModal && (
            <ColumnsModal
              visible
              onCancel={onCancelColumnSelection}
              localStorageId="ps_purchasing"
              columns={sortByOrderInArray(
                columns(),
                JSON.parse(getLocalStorageItem("columns_order")).ps_purchasing,
                "title"
              ).map((el) => el.title)}
              disabledSkuList={["sku"]}
            />
          )}
        </div>
      );
    });
  } else {
    return <p>No purchasing data was found for this item</p>;
  }
};

export default Purchasing;
