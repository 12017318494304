// import style
import "./App.scss";

// import dependencies
import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

// import components
import Navigation from "./components/Navigation.component";
import ProductSearch from "./pages/ProductSearch/ProductSearch.page";
import Login from "./pages/Login/Login.page";
import Welcome from "./pages/Welcome/Welcome.page";
import AuthenticatedRoute from "./components/AuthenticatedRoute.component";
import PurchaseOrder from "./pages/PurchaseOrder/PurchaseOrder.page";
import { validateToken } from "./services/Token.service";
import { deleteLocalStorageItem, getLocalStorageItem, setLocalStorageItem } from "./utils/LocalStorage.util";
import ErrorBoundary from "./pages/Error/Error.page";
import ProductCard from "./pages/ProductCard/ProductCard.page";
import FeatureDashboard from "./components/Feature/FeatureDashboard.component";
import { initFeatures } from "./components/Feature/Feature.component";
import { VersionCheck } from "./utils/Version.util";

// define variables
const { Footer } = Layout;

interface Notification {
  visible: boolean;
  notifications: string;
}

const App: React.FC = () => {
  const history = useHistory();
  const [notificationsModal, setNotificationsModal] = useState<Notification>({
    visible: false,
    notifications: getLocalStorageItem("notifications") && JSON.parse(getLocalStorageItem("notifications"))?.reverse(),
  });

  VersionCheck();

  const refreshNotifications = (): void => {
    setNotificationsModal({
      ...notificationsModal,
      notifications: JSON.parse(getLocalStorageItem("notifications"))?.reverse(),
    });
  };

  const initLocalStorage = () => {
    !getLocalStorageItem("preferences") &&
      setLocalStorageItem(
        "preferences",
        JSON.stringify({ dateformat: "YYYY-MM-DD", notifications: [], tableview: "style", tablePageSize: "10" })
      );

    !getLocalStorageItem("notifications") && setLocalStorageItem("notifications", JSON.stringify([]));
    !getLocalStorageItem("hidden_columns") && setLocalStorageItem("hidden_columns", JSON.stringify({}));
    !getLocalStorageItem("columns_order") && setLocalStorageItem("columns_order", JSON.stringify({}));
  };

  const UNAUTHORIZED: number = 401;

  useEffect(() => {
    initFeatures();
    initLocalStorage();

    getLocalStorageItem("token") ? validateUserLogin() : history.push("/login");
    const interval = setInterval(() => {
      getLocalStorageItem("token") ? validateUserLogin() : history.push("/login");
    }, 3600000);
    return () => clearInterval(interval);
  });

  const validateUserLogin = (): void => {
    validateToken().catch(({ response }) => {
      if (response?.status === UNAUTHORIZED) {
        deleteLocalStorageItem("token");
        history.push("/login");
      }
    });
  };

  return (
    <ErrorBoundary>
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <AuthenticatedRoute path="/">
          <Layout style={{ minHeight: "100vh" }}>
            <Navigation notificationsModal={notificationsModal} setNotificationsModal={setNotificationsModal} />
            <Layout className="site-layout">
              <Switch>
                <AuthenticatedRoute
                  path="/productsearch"
                  exact
                  accessRoles={["fjellsport-ks"]}
                  userRoles={["fjellsport-ks", "fjellsport-ik"]} //TODO: Hente brukers tilganger
                >
                  <ProductSearch />
                </AuthenticatedRoute>
                <AuthenticatedRoute
                  path="/purchaseorder"
                  exact
                  accessRoles={["fjellsport-ks"]}
                  userRoles={["fjellsport-ks", "fjellsport-ik"]} //TODO: Hente brukers tilganger
                >
                  <PurchaseOrder refreshNotifications={refreshNotifications} />
                </AuthenticatedRoute>
                <AuthenticatedRoute
                  path="/productcard/:item_no/:tab?"
                  exact
                  accessRoles={["fjellsport-ks"]}
                  userRoles={["fjellsport-ks", "fjellsport-ik"]} //TODO: Hente brukers tilganger
                >
                  <ProductCard />
                </AuthenticatedRoute>
                <AuthenticatedRoute path={["/features", "/flags"]} exact>
                  <FeatureDashboard />
                </AuthenticatedRoute>
                <Route path="/">
                  <Welcome />
                </Route>
              </Switch>
              <Footer style={{ textAlign: "center" }}>
                <p>©{new Date().getFullYear()} Outnordic Invest AB</p>
              </Footer>
            </Layout>
          </Layout>
        </AuthenticatedRoute>
      </Switch>
    </ErrorBoundary>
  );
};

export default App;
