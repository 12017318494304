import { Select } from "antd";
import moment from "moment";

export const columns = (plantId, lookups, changedData, setChangedData) => [
  {
    title: "sku",
    dataIndex: "sku",
    key: "sku",
    width: 150,
  },
  {
    title: "variant_code",
    dataIndex: "variant_code",
    key: "variant_code",
    width: 150,
  },
  {
    title: "dispo",
    dataIndex: "dispo",
    key: "dispo",
    width: 150,
    render: (text, record) => (
      <Select
        style={{ width: "100%" }}
        defaultValue={text}
        disabled
        options={null}
        onChange={(val) => setChangedData([...changedData, { plant: plantId, changed: record.sku, ekgrp: val }])}
      />
    ),
  },
  {
    title: "ekgrp",
    dataIndex: "ekgrp",
    key: "ekgrp",
    width: 150,
    render: (text, record) => (
      <Select
        style={{ width: "100%" }}
        defaultValue={text}
        disabled
        options={null}
        onChange={(val) => setChangedData([...changedData, { plant: plantId, changed: record.sku, ekgrp: val }])}
      />
    ),
  },
  {
    title: "fedas",
    dataIndex: "fedas",
    key: "fedas",
    width: 150,
    render: (text, record) => (
      <Select
        style={{ width: "100%" }}
        defaultValue={text}
        disabled
        options={null}
        onChange={(val) => setChangedData([...changedData, { plant: plantId, changed: record.sku, ekgrp: val }])}
      />
    ),
  },
  {
    title: "reorder_point",
    dataIndex: "reorder_point",
    key: "reorder_point",
  },
  {
    title: "mmsta",
    dataIndex: "mmsta",
    key: "mmsta",
  },
  {
    title: "variant_status",
    dataIndex: "variant_status",
    key: "variant_status",
  },
  {
    title: "webaz",
    dataIndex: "webaz",
    key: "webaz",
  },
  {
    title: "noos",
    dataIndex: "noos",
    key: "noos",
  },
  {
    title: "pricat_no",
    dataIndex: "pricat_no",
    key: "pricat_no",
  },
  {
    title: "prmod",
    dataIndex: "prmod",
    key: "prmod",
  },
  {
    title: "pfsitem_status",
    dataIndex: "pfsitem_status",
    key: "pfsitem_status",
  },
  {
    title: "lgrad",
    dataIndex: "lgrad",
    key: "lgrad",
  },
  {
    title: "maabc",
    dataIndex: "maabc",
    key: "maabc",
  },
  {
    title: "prctr",
    dataIndex: "prctr",
    key: "prctr",
  },
  {
    title: "plifz",
    dataIndex: "plifz",
    key: "plifz",
  },
  {
    title: "mpop",
    dataIndex: "mpop",
    key: "mpop",
  },
  {
    title: "all_season",
    dataIndex: "all_season",
    key: "all_season",
  },
  {
    title: "season_code",
    dataIndex: "season_code",
    key: "season_code",
    width: 200,
    render: (text, record) => (
      <Select
        style={{ width: "100%" }}
        defaultValue={text}
        disabled
        options={lookups.season}
        onChange={(val) => setChangedData([...changedData, { plant: plantId, changed: record.sku, season: val }])} //TOOO: Denne må endres
      />
    ),
  },
  {
    title: "dismm",
    dataIndex: "dismm",
    key: "dismm",
  },
  {
    title: "fhori",
    dataIndex: "fhori",
    key: "fhori",
  },
  {
    title: "reorder_quantity",
    dataIndex: "reorder_quantity",
    key: "reorder_quantity",
  },
  {
    title: "disls",
    dataIndex: "disls",
    key: "disls",
  },
  {
    title: "mtvfp",
    dataIndex: "mtvfp",
    key: "mtvfp",
  },
  {
    title: "Last updated",
    dataIndex: "updated_at",
    key: "updated_at",
    render: (text) => (text ? moment(text).format("DD MMMM YYYY") : "-"),
  },
  {
    title: "Created",
    dataIndex: "created_at",
    key: "created_at",
    render: (text) => (text ? moment(text).format("DD MMMM YYYY") : "-"),
  },
];
