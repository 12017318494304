import { Radio } from "antd";
import { getPreference, updatePreferenceObj } from "utils/LocalStorage.util";
import { useState } from "react";

const TablePageSize = () => {
  const [tablePageSize, setTablePageSize] = useState(getPreference("tablePageSize"));

  const onChange = (e) => {
    setTablePageSize(e.target.value);
    updatePreferenceObj("tablePageSize", e.target.value);
  };

  return (
    <>
      <h3>Default page size</h3>
      <Radio.Group
        onChange={onChange}
        value={tablePageSize}
        style={{ display: "grid", gridTemplateColumns: "100%", gap: "10px" }}
      >
        <Radio value="10">10</Radio>
        <Radio value="20">20</Radio>
        <Radio value="50">50</Radio>
        <Radio value="100">100</Radio>
      </Radio.Group>
    </>
  );
};

export default TablePageSize;
