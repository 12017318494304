import { useState, useEffect } from "react";
import { Table, Button, Input, Row, Col, Tooltip, Space } from "antd";

import { columns } from "./VariantColumns";
import ContextMenu, { openContextMenu } from "../ContextMenu/ContextMenu.component";

import Fuse from "fuse.js";
import { getLocalStorageItem, setLocalStorageItem, getTablePageSize } from "utils/LocalStorage.util";
import { SettingOutlined } from "@ant-design/icons";
import ColumnsModal from "../Modal/ColumnsModal.component";
import { sortByOrderInArray } from "utils/Sort.util";

const searchOptions = {
  ignoreLocation: true,
  threshold: 0.2,
  minMatchCharLength: 1,
  keys: ["stylename", "sku"],
};

const ProductVariant = ({ data, lookups }) => {
  const [filter, setFilter] = useState("");
  const [changedData, setChangedData] = useState([]);
  const [saveButton, setSaveButton] = useState({ disabled: true, text: "Save" });
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [contextMenu, setContextMenu] = useState({
    selectedRows,
    visible: false,
    x: 0,
    y: 0,
  });
  const [showColumnModal, setShowColumnModal] = useState(false);
  const [hiddenColumnsList, setHiddenColumnsList] = useState(
    getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns")).ps_variant
      ? JSON.parse(getLocalStorageItem("hidden_columns")).ps_variant
      : []
  );

  useEffect(() => {
    const hidden_colums = getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns"));
    !hidden_colums.ps_variant && setLocalStorageItem("hidden_columns", JSON.stringify({ ...hidden_colums, ps_variant: [] }));

    const columns_order = getLocalStorageItem("columns_order") && JSON.parse(getLocalStorageItem("columns_order"));
    !columns_order.ps_variant && setLocalStorageItem("columns_order", JSON.stringify({ ...columns_order, ps_variant: [] }));
  }, []);

  useEffect(() => {
    changedData.length > 0 && setSaveButton({ disabled: false, text: `Save changes (${changedData.length})` });
  }, [changedData]);

  const rowSelection = {
    selectedRows,
    onChange: (keys, rows) => setSelectedRows({ keys, rows }),
  };

  const saveChanges = () => {
    // push data to api here
    setChangedData([]);
    setSaveButton({ disabled: true, text: "Changes was saved" });
  };

  const filterTableOnSearch = (data, options) => {
    if (filter !== "") {
      const fuse = new Fuse(data, options);
      return fuse.search(filter).map((obj) => (obj = obj.item));
    } else {
      return data;
    }
  };

  const onCancelColumnSelection = () => {
    setShowColumnModal(false);
    setHiddenColumnsList(JSON.parse(getLocalStorageItem("hidden_columns")).ps_variant);
  };

  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical">
        <Row style={{ marginBottom: "2em" }}>
          <Col span="6">
            <Input placeholder="Filter products" onChange={(i) => setFilter(i?.target?.value)} />
          </Col>
          <Col span="2">
            <Button type="primary" disabled={saveButton.disabled} onClick={saveChanges}>
              {saveButton.text}
            </Button>
          </Col>
          <Col offset="15" span="1">
            <Tooltip title="Configure table columns">
              <Button shape="circle" icon={<SettingOutlined />} onClick={() => setShowColumnModal(true)} />
            </Tooltip>
          </Col>
        </Row>
        <Table
          rowKey="sku"
          columns={sortByOrderInArray(
            columns(changedData, setChangedData, lookups),
            JSON.parse(getLocalStorageItem("columns_order")).ps_variant,
            "title"
          ).filter((column) => !hiddenColumnsList.includes(column.title))}
          dataSource={filterTableOnSearch(data, searchOptions)}
          tabIndex="-1"
          rowSelection={rowSelection}
          pagination={{ position: ["bottomCenter"], defaultPageSize: getTablePageSize() }}
          onRow={(record) => {
            return {
              onContextMenu: (event) => openContextMenu(record, contextMenu, setContextMenu, selectedRows, event),
            };
          }}
          scroll={{ x: 8000 }}
          bordered
          size="small"
        />
      </Space>
      <ContextMenu {...contextMenu} />
      {showColumnModal && (
        <ColumnsModal
          visible
          onCancel={onCancelColumnSelection}
          localStorageId="ps_variant"
          columns={sortByOrderInArray(
            columns(changedData, setChangedData, lookups),
            JSON.parse(getLocalStorageItem("columns_order")).ps_variant,
            "title"
          ).map((el) => el.title)}
          disabledSkuList={["sku"]}
        />
      )}
    </>
  );
};

export default ProductVariant;
