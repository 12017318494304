import { useState } from "react";
import { Modal, Row, Col, Divider, Checkbox, Button } from "antd";
import { PropTypes } from "prop-types";
import { getLocalStorageItem, setLocalStorageItem } from "utils/LocalStorage.util";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MenuOutlined } from "@ant-design/icons";

const ColumnsModal = ({ visible, onCancel, localStorageId, columns, disabledSkuList }) => {
  const [hiddenColumnsList, setHiddenColumnsList] = useState(
    JSON.parse(getLocalStorageItem("hidden_columns"))[localStorageId]
  );

  const [columnsOrderList, setColumnsOrderList] = useState(columns);

  const hiddenColumnsObject = JSON.parse(getLocalStorageItem("hidden_columns"));
  const columnsOrderObject = JSON.parse(getLocalStorageItem("columns_order"));

  const handleCheckboxChange = (checked, columnName) => {
    const newArray = [...hiddenColumnsList];
    if (!checked) {
      newArray.push(columnName);
    } else {
      newArray.splice(newArray.indexOf(columnName), 1);
    }

    setHiddenColumnsList(newArray);
    hiddenColumnsObject[localStorageId] = newArray;
    setLocalStorageItem("hidden_columns", JSON.stringify(hiddenColumnsObject));
  };

  const onDragEnd = (movedItem) => {
    if (movedItem.source && movedItem.destination) {
      const orderList = [...columnsOrderList];
      const element = orderList[movedItem.source.index];
      orderList.splice(movedItem.source.index, 1);
      orderList.splice(movedItem.destination.index, 0, element);

      setColumnsOrderList(orderList);
      columnsOrderObject[localStorageId] = orderList;
      setLocalStorageItem("columns_order", JSON.stringify(columnsOrderObject));
    }
  };

  const ListItem = ({ columnTitle, index }) => {
    return (
      <Draggable draggableId={columnTitle} index={index}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <Row gutter={16} justify="space-between" align="center">
              <Col span={4}>
                <MenuOutlined />
              </Col>
              <Col span={12}>{columnTitle}</Col>
              <Col span={2} offset={6}>
                <Checkbox
                  defaultChecked={!hiddenColumnsList.includes(columnTitle)}
                  disabled={disabledSkuList.includes(columnTitle)}
                  onChange={(e) => handleCheckboxChange(e.target.checked, columnTitle)}
                ></Checkbox>
              </Col>
              <Divider />
            </Row>
          </div>
        )}
      </Draggable>
    );
  };

  const reset = () => {
    hiddenColumnsObject[localStorageId] = [];
    setLocalStorageItem("hidden_columns", JSON.stringify(hiddenColumnsObject));
    setHiddenColumnsList([]);

    columnsOrderObject[localStorageId] = [];
    setLocalStorageItem("columns_order", JSON.stringify(columnsOrderObject));
    onCancel(false);
  };

  return (
    <Modal
      className="ComponentsModal"
      title="Hide/show columns and arrange column order"
      centered
      visible={visible}
      onCancel={() => onCancel(false)}
      footer={[
        <Button key="reset" onClick={reset}>
          Reset
        </Button>,
      ]}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {columnsOrderList.map((title, index) => (
                <ListItem columnTitle={title} key={title} index={index} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );
};

ColumnsModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default ColumnsModal;
