import { Select, Input, Collapse, Row, Col } from "antd";
import { isFeatureAvailable } from "components/Feature/Feature.component";

// import BraftEditor from "braft-editor";
// import "braft-editor/dist/index.css";

import "./Info.page.scss";

const { Panel } = Collapse;

const searchSelectProps = {
  showSearch: true,
  filterOption: (input, option) =>
    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
};

const Info = ({ data, userChanges, setUserChanges, lookups }) => {
  const setChanges = (key, input) => {
    setUserChanges({ ...userChanges, item: { ...userChanges.item, [key]: input } });
  };

  const getFieldValue = (key) => {
    return userChanges?.item && userChanges.item[key] !== undefined ? userChanges.item[key] : data?.item && data?.item[key];
  };

  return (
    <>
      <Row className="info-row" justify="space-between" gutter={[24, 24]}>
        <Col span="12">
          <label>Description</label>
          <Input
            placeholder=""
            value={getFieldValue("description")}
            onChange={(input) => setChanges("description", input.target.value)}
          />
        </Col>
        <Col span="12">
          <label>Brand</label>
          <Input
            placeholder=""
            value={getFieldValue("mfrnr")}
            onChange={(input) => setChanges("mfrnr", input.target.value)}
            disabled
          />
        </Col>
        <Col span="12">
          <Collapse ghost style={{ display: isFeatureAvailable("description_translation") ? "block" : "none" }}>
            <Panel header="Description translations" key="1">
              <label>Description (Swedish)</label>
              <Input
                placeholder=""
                value={getFieldValue("description_se")}
                onChange={(input) => setChanges("description_se", input.target.value)}
              />
              <label>Description (Norwegian)</label>
              <Input
                placeholder=""
                value={getFieldValue("description_no")}
                onChange={(input) => setChanges("description_no", input.target.value)}
              />
              <label>Description (English)</label>
              <Input
                placeholder=""
                value={getFieldValue("description_en")}
                onChange={(input) => setChanges("description_en", input.target.value)}
              />
              <label>Description (German)</label>
              <Input
                placeholder=""
                value={getFieldValue("description_de")}
                onChange={(input) => setChanges("description_de", input.target.value)}
              />
              <label>Description (Finish)</label>
              <Input
                placeholder=""
                value={getFieldValue("description_fi")}
                onChange={(input) => setChanges("description_fi", input.target.value)}
              />
              <label>Description (Danish)</label>
              <Input
                placeholder=""
                value={getFieldValue("description_dk")}
                onChange={(input) => setChanges("description_dk", input.target.value)}
              />
            </Panel>
          </Collapse>
        </Col>
        <Col span="12">
          <label>Fedas</label>
          <Select
            showSearch
            name="fedas"
            placeholder=""
            value={getFieldValue("fedas")}
            onChange={(input) => setChanges("fedas", input)}
            options={lookups?.fedas}
            optionFilterProp="label"
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </Col>
        <Col span="12">
          <label>Stylename</label>
          <Input
            placeholder=""
            value={getFieldValue("stylename")}
            onChange={(input) => setChanges("stylename", input.target.value)}
            disabled
          />
        </Col>
        <Col span="12">
          <label>Collection</label>
          <Select
            {...searchSelectProps}
            placeholder=""
            value={getFieldValue("pfscollection")}
            onChange={(input) => setChanges("pfscollection", input)}
            options={lookups?.collection_code}
          />
        </Col>

        <Col span="12">
          <label>Sex</label>
          <Select
            {...searchSelectProps}
            placeholder=""
            value={getFieldValue("sex")}
            onChange={(input) => setChanges("sex", input)}
            options={lookups?.sex_code}
          />
        </Col>
        <Col span="12">
          <label>Pfsseason</label>
          <Select
            {...searchSelectProps}
            placeholder=""
            value={getFieldValue("pfsseason")}
            onChange={(input) => setChanges("pfsseason", input)}
            options={lookups?.season}
            disabled
          />
        </Col>
        <Col span="12">
          <label>Gender</label>
          <Select
            {...searchSelectProps}
            placeholder=""
            value={getFieldValue("gender")}
            onChange={(input) => setChanges("gender", input)}
            options={lookups?.gender}
            disabled
          />
        </Col>
        <Col span="12">
          <label>Season</label>
          <Select
            {...searchSelectProps}
            placeholder=""
            value={getFieldValue("season")}
            onChange={(input) => setChanges("season", input)}
            disabled
          />
        </Col>
      </Row>
    </>
  );
};

export default Info;
