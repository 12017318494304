import { Item, StyleItem } from "../interfaces/Item.interface";

export const fromSkuListToStyleList = (skuList: Item[]): StyleItem[] => {
  const styleList: string[] = [];
  const styleListObjects: StyleItem[] = [];

  skuList.forEach((item) => {
    if (!styleList.includes(item.stylename)) {
      styleList.push(item.stylename);
      const colorList: string[] = [];
      let colorListObjects: object[] = [];
      let style_stock: number = 0;
      let style_ZMENG1: number = 0;
      let style_ZMENG2: number = 0;
      let style_ZMENG3: number = 0;
      let style_ZMENG4: number = 0;
      let style_OMENG: number = 0;

      const itemsInStyle = skuList.filter((el) => el.stylename === item.stylename);

      const containsAColorVariant = itemsInStyle.some(
        (styleitem) => styleitem.color_description !== "" && styleitem.color_description !== null
      );

      if (containsAColorVariant) {
        itemsInStyle.forEach((styleElement) => {
          style_stock += styleElement.is_in_stock || 0;
          style_ZMENG1 += styleElement.ZMENG1 || 0;
          style_ZMENG2 += styleElement.ZMENG2 || 0;
          style_ZMENG3 += styleElement.ZMENG3 || 0;
          style_ZMENG4 += styleElement.ZMENG4 || 0;
          style_OMENG += styleElement.OMENG || 0;

          if (!colorList.includes(styleElement.color_description)) {
            let color_stock: number = 0;
            let color_ZMENG1: number = 0;
            let color_ZMENG2: number = 0;
            let color_ZMENG3: number = 0;
            let color_ZMENG4: number = 0;
            let color_OMENG: number = 0;

            colorList.push(styleElement.color_description);

            const itemsInColor = itemsInStyle
              .filter((sku) => sku.color_description === styleElement.color_description)
              .map((sku) => ({
                ...sku,
                key: sku.sku,
                id: sku.sku,
              }));

            itemsInColor.forEach((colorItem) => {
              color_stock += colorItem.is_in_stock || 0;
              color_ZMENG1 += colorItem.ZMENG1 || 0;
              color_ZMENG2 += colorItem.ZMENG2 || 0;
              color_ZMENG3 += colorItem.ZMENG3 || 0;
              color_ZMENG4 += colorItem.ZMENG4 || 0;
              color_OMENG += colorItem.OMENG || 0;
            });

            colorListObjects.push({
              size_description: itemsInColor[0].color_description,
              key: `${itemsInColor[0].stylename}@@@${
                itemsInColor[0].color_description ? itemsInColor[0].color_description : ""
              }`,
              id: `${itemsInColor[0].stylename}@@@${
                itemsInColor[0].color_description ? itemsInColor[0].color_description : ""
              }`,
              parentId: `${itemsInColor[0].stylename}`,
              children: itemsInColor,
              is_in_stock: color_stock,
              ZMENG1: color_ZMENG1,
              ZMENG2: color_ZMENG2,
              ZMENG3: color_ZMENG3,
              ZMENG4: color_ZMENG4,
              OMENG: color_OMENG,
              MFRNR: item.MFRNR,
              stylename: item.stylename,
              vendor_sku: item.vendor_sku,
            });
          }
        });
      } else {
        const skuArray = itemsInStyle.map((el) => {
          style_stock += el.is_in_stock || 0;
          style_ZMENG1 += el.ZMENG1 || 0;
          style_ZMENG2 += el.ZMENG2 || 0;
          style_ZMENG3 += el.ZMENG3 || 0;
          style_ZMENG4 += el.ZMENG4 || 0;
          style_OMENG += el.OMENG || 0;

          return { ...el, size_description: el.size_description ? el.size_description : "One Size", key: el.sku, id: el.sku };
        });

        colorListObjects = skuArray;
      }

      styleListObjects.push({
        size_description: item.stylename,
        key: item.stylename,
        id: item.stylename,
        MFRNR: item.MFRNR,
        stylename: item.stylename,
        vendor_sku: item.vendor_sku,
        children: colorListObjects,
        is_in_stock: style_stock,
        ZMENG1: style_ZMENG1,
        ZMENG2: style_ZMENG2,
        ZMENG3: style_ZMENG3,
        ZMENG4: style_ZMENG4,
        OMENG: style_OMENG,
      });
    }
  });

  return styleListObjects;
};
