import axios from "axios";
import { getLocalStorageItem } from "utils/LocalStorage.util";

const httpRequest = axios.create({ baseURL: process.env.REACT_APP_API_URL });

httpRequest.interceptors.request.use(
  async (config) => {
    const token = getLocalStorageItem("token");
    if (token && config.headers) {
      config.headers.Authorization = `Bearer  ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default httpRequest;
