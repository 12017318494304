import React from "react";
import { Menu, Button } from "antd";

const DropdownMenu = ({ selectAll, setSelectedRowKeys, selectAllBack, selectAllNoos }) => (
  <Menu>
    <Menu.Item>
      <Button type="link" onClick={() => selectAll()}>
        Select all in current selection
      </Button>
    </Menu.Item>
    <Menu.Item>
      <Button type="link" onClick={() => setSelectedRowKeys([])}>
        Clear all selected
      </Button>
    </Menu.Item>
    <Menu.Item>
      <Button type="link" onClick={() => selectAllBack()}>
        Select back in current selection
      </Button>
    </Menu.Item>
    <Menu.Item>
      <Button type="link" onClick={() => selectAllNoos()}>
        Select noos in current selection
      </Button>
    </Menu.Item>
  </Menu>
);

export default DropdownMenu;
