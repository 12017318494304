export const columns = (goToPage, hiddenColumnsList) => [
  {
    key: "item_no",
    dataKey: "item_no",
    title: "Product Id",
    width: 200,
    sortable: true,
    cellRenderer: ({ rowData, cellData }) => {
      return rowData?.match_type === "item" ? (
        <span className="pimLink" onClick={() => goToPage("/productcard/" + rowData.item_no)}>
          {cellData}
        </span>
      ) : (
        <span>{cellData}</span>
      );
    },
  },
  {
    key: "mfrnr",
    dataKey: "mfrnr",
    title: "Brand",
    width: 200,
    sortable: true,
    hidden: hiddenColumnsList.includes("Brand"),
  },
  {
    key: "model_no",
    dataKey: "model_no",
    title: "Model nb",
    width: 200,
    sortable: true,
    hidden: hiddenColumnsList.includes("Model nb"),
  },
  {
    key: "stylename",
    dataKey: "stylename",
    title: "Stylename",
    width: 350,
    resizable: true,
    sortable: true,
    cellRenderer: ({ rowData, cellData }) => {
      return rowData?.match_type === "item" ? (
        <span className="pimLink" onClick={() => goToPage("/productcard/" + rowData.item_no)}>
          {cellData}
        </span>
      ) : (
        <span>{cellData}</span>
      );
    },
    hidden: hiddenColumnsList.includes("Stylename"),
  },
  {
    key: "sex",
    dataKey: "sex",
    title: "Sex",
    width: 200,
    sortable: true,
    hidden: hiddenColumnsList.includes("Sex"),
  },
  {
    key: "fedas",
    dataKey: "fedas",
    title: "Fedas",
    width: 300,
    sortable: true,
    cellRenderer: ({ cellData, column }) => {
      return column?.lookups?.fedas && column?.lookups?.fedas.filter((fedas) => fedas.value === cellData)[0]?.label;
    },
    hidden: hiddenColumnsList.includes("Fedas"),
  },
  {
    key: "pfsseason",
    dataKey: "pfsseason",
    title: "Season",
    width: 300,
    sortable: true,
    cellRenderer: ({ cellData, column }) => {
      return column?.lookups?.season && column?.lookups?.season.filter((season) => season.value === cellData)[0]?.label;
    },
    hidden: hiddenColumnsList.includes("Season"),
  },
  {
    key: "pfscollection",
    dataKey: "pfscollection",
    title: "Category",
    width: 300,
    sortable: true,
    cellRenderer: ({ cellData, column }) => {
      return (
        column?.lookups?.collection_code &&
        column?.lookups?.collection_code.filter((collection_code) => collection_code.value === cellData)[0]?.label
      );
    },
    hidden: hiddenColumnsList.includes("Category"),
  },
];
