import { Radio } from "antd";
import { getPreference, updatePreferenceObj } from "utils/LocalStorage.util";
import { useState } from "react";

const TableView = () => {
  const [tableView, setTableView] = useState(getPreference("tableview"));

  const onChange = (e) => {
    setTableView(e.target.value);
    updatePreferenceObj("tableview", e.target.value);
  };

  return (
    <>
      <h3>Table View</h3>
      <Radio.Group
        onChange={onChange}
        value={tableView}
        style={{ display: "grid", gridTemplateColumns: "100%", gap: "10px" }}
      >
        <Radio value="style">Style</Radio>
        <Radio value="sku">SKU</Radio>
      </Radio.Group>
    </>
  );
};

export default TableView;
