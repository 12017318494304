export const sortStrings = (a: string, b: string): number =>
  a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });

export const sortByKey = (data: any[], key: string, order: string): object[] => {
  return order === "asc" ? data.sort((a, b) => (a[key] > b[key] ? 1 : -1)) : data.sort((a, b) => (b[key] < a[key] ? -1 : 1));
};

export const sortByOrderInArray = (array: any[], sortedArray: string[] = [], key: string): any[] => {
  if (!key) {
    return sortedArray.concat(array.filter((el) => !sortedArray.includes(el)));
  } else {
    let newArray: any[] = [];

    sortedArray.forEach((el) => {
      const itemsInArray = array.filter((i) => i[key] === el);
      newArray = newArray.concat(itemsInArray);
    });

    return newArray.concat(array.filter((el) => !sortedArray.includes(el[key])));
  }
};
