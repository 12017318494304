import { Select, Table, Row, Button, Tooltip } from "antd";
import { useState, useEffect } from "react";
import { columns } from "./ProductPlantColumns";
import ContextMenu, { openContextMenu } from "components/ContextMenu/ContextMenu.component";
import { getLocalStorageItem, setLocalStorageItem, getTablePageSize } from "utils/LocalStorage.util";
import { SettingOutlined } from "@ant-design/icons";
import ColumnsModal from "components/Modal/ColumnsModal.component";
import { sortByOrderInArray } from "utils/Sort.util";

const ProductPlant = ({ data, lookups, userChanges, setUserChanges }) => {
  const [visiblePlants, setVisiblePlants] = useState(["1100", "5000"]);
  const [selectedRows, setSelectedRows] = useState({ keys: [], rows: [] });
  const [contextMenu, setContextMenu] = useState({
    selectedRows,
    visible: false,
    x: 0,
    y: 0,
  });
  const [showColumnModal, setShowColumnModal] = useState(false);
  const [hiddenColumnsList, setHiddenColumnsList] = useState(
    getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns")).ps_plant
      ? JSON.parse(getLocalStorageItem("hidden_columns")).ps_plant
      : []
  );

  useEffect(() => {
    const hidden_colums = getLocalStorageItem("hidden_columns") && JSON.parse(getLocalStorageItem("hidden_columns"));
    !hidden_colums.ps_plant && setLocalStorageItem("hidden_columns", JSON.stringify({ ...hidden_colums, ps_plant: [] }));

    const columns_order = getLocalStorageItem("columns_order") && JSON.parse(getLocalStorageItem("columns_order"));
    !columns_order.ps_plant && setLocalStorageItem("columns_order", JSON.stringify({ ...columns_order, ps_plant: [] }));
  }, []);

  const setChanges = (key, input) => {
    setUserChanges({ ...userChanges, info: { ...userChanges.info, [key]: input } });
  };

  const getFieldValue = (key) => {
    return userChanges?.info && userChanges.info[key] !== undefined ? userChanges.info[key] : data.plant && data.plant[key];
  };

  const rowSelection = {
    selectedRowKeys: selectedRows.keys,
    onChange: (keys, rows) => setSelectedRows({ keys, rows }),
  };

  const GetSkusThatBelongToPlant = (plantId) => {
    return data.filter((sku) => sku.plant === plantId);
  };

  const onCancelColumnSelection = () => {
    setShowColumnModal(false);
    setHiddenColumnsList(JSON.parse(getLocalStorageItem("hidden_columns")).ps_plant);
  };

  return (
    <>
      <Row justify="space-between">
        <Select
          style={{ width: "300px", marginBottom: "2em" }}
          placeholder="Filter plant"
          onChange={(i) => setVisiblePlants(i)}
        >
          <Select.Option value="[1100, 5000]">Show all plants</Select.Option>
          <Select.Option value="5000">5000 Outnorth Göteborg</Select.Option>
          <Select.Option value="1100">1100 Fjellsport Klinestadmoen</Select.Option>
        </Select>
        <Tooltip title="Configure table columns">
          <Button shape="circle" icon={<SettingOutlined />} onClick={() => setShowColumnModal(true)} />
        </Tooltip>
      </Row>

      {visiblePlants.includes("5000") && (
        <div>
          <h1>5000 Outnorth Göteborg</h1>
          <Table
            rowKey="sku"
            columns={sortByOrderInArray(
              columns("5000", lookups, getFieldValue, setChanges),
              JSON.parse(getLocalStorageItem("columns_order")).ps_plant,
              "title"
            ).filter((column) => !hiddenColumnsList.includes(column.title))}
            dataSource={GetSkusThatBelongToPlant("5000")}
            bordered
            rowSelection={rowSelection}
            pagination={{ position: ["bottomCenter"], defaultPageSize: getTablePageSize() }}
            onRow={(record) => {
              return {
                onContextMenu: (event) => openContextMenu(record, contextMenu, setContextMenu, selectedRows, event),
              };
            }}
            size="small"
            tabIndex="-1"
            scroll={{ x: 3000 }}
          />
          <ContextMenu {...contextMenu} />
        </div>
      )}

      {visiblePlants.includes("1100") && (
        <div>
          <h1>1100 Fjellsport Klinestadmoen</h1>
          <Table
            rowKey="sku"
            columns={sortByOrderInArray(
              columns("1100", lookups, getFieldValue, setChanges),
              JSON.parse(getLocalStorageItem("columns_order")).ps_plant,
              "title"
            ).filter((column) => !hiddenColumnsList.includes(column.title))}
            dataSource={GetSkusThatBelongToPlant("1100")}
            bordered
            rowSelection={rowSelection}
            pagination={{ position: ["bottomCenter"], defaultPageSize: getTablePageSize() }}
            onRow={(record) => {
              return {
                onContextMenu: (event) => openContextMenu(record, contextMenu, setContextMenu, selectedRows, event),
              };
            }}
            size="small"
            tabIndex="-1"
            scroll={{ x: 3000 }}
          />
          <ContextMenu {...contextMenu} />
        </div>
      )}
      {showColumnModal && (
        <ColumnsModal
          visible
          onCancel={onCancelColumnSelection}
          localStorageId="ps_plant"
          columns={sortByOrderInArray(
            columns("", lookups, getFieldValue, setChanges),
            JSON.parse(getLocalStorageItem("columns_order")).ps_plant,
            "title"
          ).map((el) => el.title)}
          disabledSkuList={["sku"]}
        />
      )}
    </>
  );
};

export default ProductPlant;
