import React from "react";
import { Route, useHistory, useLocation } from "react-router-dom";

const AuthenticatedRoute = (props) => {
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");

  if (!token) {
    history.push("/login");
    return null;
  }

  return location.pathname !== "/" && props.accessRoles?.length && props.userRoles?.length ? (
    <AuthenticateAccess history={history} {...props} />
  ) : (
    <Route {...props} />
  );
};

const AuthenticateAccess = ({ history, ...props }) => {
  const userHasRequiredRole = props.accessRoles.some((role) => props.userRoles.includes(role));

  if (userHasRequiredRole) {
    return <Route {...props} />;
  } else {
    history.push("/");
    return null;
  }
};

export default AuthenticatedRoute;
