import HttpRequest from "../bootstrap/axios";

export const FetchProductData = (id: string) => HttpRequest.get("/tmp/api/item/" + id);

export const FetchLookupData = (tab: string) => HttpRequest.get("/tmp/api/ui/" + tab + "/lookups");

export const FetchRecentData = () => HttpRequest.get("/tmp/api/items/recents");

export const FetchProductSearch = (query: string) => HttpRequest.get("/tmp/api/items/search?q=" + query);

export const SyncItem = (item_no: string) => HttpRequest.post("/tmp/api/item/" + item_no + "/sync");

export const SaveItemChanges = (item_no: string, changes: any) =>
  HttpRequest.post("/tmp/api/item/" + item_no + "/mutate", changes);
