import React, { useMemo } from "react";
import { Popover, Button } from "antd";

const CreatePoButton = ({
  sapCredentials,
  selectedRowKeys,
  modifiedDataList,
  handleCreatePoButtonPressed,
  creatingPo,
  poGroup,
}) => {
  const validations = useMemo(() => {
    const issueArray = [];

    if (!selectedRowKeys.length) {
      issueArray.push("* No products selected");
    } else {
      const missingMrpOrDelivery = selectedRowKeys.some((sku) => {
        const mrpObject = modifiedDataList.find((m) => m.key === sku);
        if (!mrpObject) {
          return true;
        }
        return !mrpObject.mrp || mrpObject.mrp === 0;
      });

      missingMrpOrDelivery && issueArray.push("* One or more selected rows is missing MRP value");
    }

    !sapCredentials.user && issueArray.push("* Missing SAP username");
    !sapCredentials.password && issueArray.push("* Missing SAP password");
    !poGroup && issueArray.push("* Missing purchasing group");

    return issueArray;
  }, [sapCredentials, selectedRowKeys, modifiedDataList, poGroup]);

  const validationContent = (
    <div>
      {validations.map((issue) => (
        <p key={issue} className="PreparePO__requiredSteps">
          {issue}
        </p>
      ))}
    </div>
  );

  const PoButton = () => (
    <Button
      type="primary"
      shape="round"
      onClick={handleCreatePoButtonPressed}
      size="medium"
      loading={creatingPo}
      disabled={
        selectedRowKeys.length === 0 ||
        !sapCredentials.user ||
        sapCredentials.user === "" ||
        !sapCredentials.password ||
        sapCredentials.password === "" ||
        !poGroup ||
        selectedRowKeys.some((sku) => {
          const mrpObject = modifiedDataList.find((m) => m.key === sku);
          if (!mrpObject) {
            return true;
          }
          return !mrpObject.mrp || !mrpObject.deliveryDate || mrpObject.mrp === 0;
        })
      }
    >
      {creatingPo ? "Creating PO" : "Create PO"}
    </Button>
  );
  return (
    <>
      {validations.length ? (
        <Popover content={validationContent} title="Required steps to create po" placement="topRight" arrowPointAtCenter>
          {PoButton()}
        </Popover>
      ) : (
        PoButton()
      )}
    </>
  );
};

export default CreatePoButton;
